/* ---------  dropdown css  ---------------*/
  .overlayDropdown {
    max-height: 9rem;
    overflow: overlay;
  }
  .overlayDropdown_12 {
    max-height: 12rem;
    overflow: overlay;
  }
  .overlay_36{
    max-height: 36vh;
    overflow: overlay;
  }
  .overlay_48 {
    max-height: 48vh;
    overflow: overlay;
  }
  .overlay_70{
    max-height: 70vh;
    overflow: overlay;
  }
  .fullwidth {
    width: 99%;
  }
  .width96 {
    width: 96%;
  }
  .width75 {
    width: 75%;
  }
  .width19 {
    width: 19vw;
  }
  .width10 {
    width: 10rem;
  }
  .width22{
    width: 22%;
  }
  .width28{
    width: 28%;
  }
  .marginRight {
    margin-right: 2.8rem;
  }
  /* ------------  common popup css ----------- */
  .centerAlign {
    text-align: center;
  }
  .flexSpaceBw {
    display: flex;
    justify-content: space-between;
  }
  .flexWrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flexSpaceBw_baseline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .flexGrow{
    flex-grow: 1;
  }
  
  .addTableBtn {
    position: fixed;
    bottom: 26px;
    right: 18px;
  }
  .confirmBtn {
    padding: 0.8rem 1.6rem;
    border-radius: 25px;
    background-color: #009688;
    outline: none;
    border: none;
    color: white;
    font-weight: bolder;
    font-size: 1rem;
  }
  .disableBtn{
    padding: 0.8rem 1.6rem;
    border-radius: 25px;
    background-color: #cccccc;;
    outline: none;
    border: none;
    color: white;
    font-weight: bolder;
    font-size: 1rem;
    cursor: not-allowed;
  }
  .popupWidthS {
    width: 26rem;
    left: 34vw;
  }
  .popupWidthM{
    left: 25vw;
    width: 46vw;
  }
  .popupWidthB {
    width: 64vw;
    left: 18vw;
  }
  .popupWidthXL{
    width: 70vw;
    left: 14vw;
    /* top:20vh; */
  }
  .popupWidthXXL{
    width:90vw;
    left:3vw;
    top:5vh;
  }
  .inputContainer {
    text-align: left;
    padding: 0.8rem 5%;
    font-size: 0.9vw;
  }
  .btnContainer {
    margin: 2rem 33% 0.6rem 33%;
  }
  .btnContainerflex{
    display: flex;
    justify-content: space-between;
    margin: 1.6rem 8%;
  }
  .btn{
    padding: 0.4rem 0.6rem;
    border-radius: 6px;
    width: 9rem;
    border: none;
    font-size: 0.9rem;
  }
  .btnL{
    width: 14rem !important;

  }
  .successBtn{
    background: #009688;
    color: white;
    border: 1px solid #009688;
  }
  .secondaryBtn{
    color: #009688;
    background-color: #fff;
    border: 1px solid #009688;
  }
  .cellPd{
    padding: 0.7rem 0.4rem;
  }
  .redBackgroundColor {
    background-color: #ffd7d7;
  }
  .greenBackgroundColor {
    background-color: #ccffcc;
  }
  /* ------------ Table DashBoard css -------------  */
  
  .flexRowReverse {
    display: flex;
    flex-direction: row-reverse;
  }
  .gridListBtn {
    float: right;
    border: 2px solid #009688;
    border-radius: 10px;
    background-color: white;
    color: #009688;
    /* margin: 0.6rem 1rem; */
    letter-spacing: 1px;
    cursor: pointer;
  }
  .gridListBtn div {
    padding: 0.2rem 0.4rem;
    margin: -1px;
  }
  .active {
    color: white;
    background: #009688;
    border-radius: 8px;
  }

  /*********************/
  .popup_title{
    border: 1px solid #ededed;
    background-color: #ededed;
    border-radius: 10px;
    display: flex;
    font-weight: 600;
    padding: 10px; 
    text-align: left;
  }
  .popup_body{
    border: 1px solid #d8e1e0;
    border-radius: 10px;
    display: flex;
    margin-top: 1.4rem;
    padding: 30px; 
    text-align: left;
  }
  .width_20{
    width: 20%;
  }
  .width_15{
    width: 15%;
  }
  .width_25{
    width: 25%;
  }
  .width_30{
    width: 30%;
  }
  .width_40{
    width: 40%;
  }
  .width_50{
    width:50%;
  }
  .searchBar{
    padding: 0.4rem 0.6rem;
    /* border-radius: 5px; */
    outline: none;
    border: none;
    border-bottom: 2px solid #ccc;
    margin-bottom: 5px;
  }
  .searchBar:focus{
    border-bottom: 2px solid #2a9c8d;
  }
  .sku_list{
    grid-template-columns: 0.25fr 0.5fr 2.5fr 1fr;
    padding: 0.8rem 0;
    border-bottom: 1px dashed #ccc;
  }
  
  .banner_list{
    grid-template-columns: 0.25fr 1fr 1fr;
    padding: 0.8rem 0;
    border-bottom: 1px dashed #ccc;
  }
  
  .update_banner_list{
    grid-template-columns:  1fr 1fr 0.25fr;
    padding: 0.8rem 0;
    border-bottom: 1px dashed #ccc;
  }

  .update_sku_list{
    grid-template-columns: 0.5fr 2.5fr 1fr 0.25fr;
    padding: 0.8rem 0;
    border-bottom: 1px dashed #ccc;
  }
  .sku_img{
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  .inputField{
    padding: 0.4rem;
    border-radius: 6px;
    border:1px solid #fff;
    outline-style: auto;
    outline-color: #c1c1c1;
    font-size: 0.9vw;
  }
  .inputField:focus{
    outline-color: #2a9d8f;
  }
  .inputField:disabled{
    outline-color: #ccc;
    color: #ccc;
  }
  .width100{
    width: 100%;
  }
  textarea{
    /* width: 242px;
    height: 93px; */
    border:1px solid #fff;
    outline-style: auto;
    outline-color: #2a9d8f;
    font-size: 1.1rem;
    padding: 0.4rem;
  }
  .noResize {
    resize: none;
  }
  /************************/
.dropdown{
    min-width:200px;   
}
.dropdownMenu{
    background-color: #fff;
    border: 1px solid #f8f8f8;
    outline-style: auto;
    outline-color: #2a9d8f;
    padding: 0.6rem;
    min-width: 200px;
    border-radius: 5px;
    position: relative;
    text-transform: capitalize;
    font-size: 0.9rem;
}
.dropdownList{
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid lightgray;
    border-radius: 9px;
    min-width: 200px;
    max-height: 120px;
    overflow-y: scroll;
    position: absolute;
}
.dropdownList li{
    background-color: white;
    border-bottom: 1px solid lightgray;
    padding: 9px 15px;
    text-transform: capitalize;
    font-size: 0.9rem;
}
.dropdownList li:first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
 }
 .dropdownList li:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
 }
 .dropdownList li:hover{
    background-color: #eef2f5;
    cursor: pointer;
 } 
 .mrg_x_6{
  margin: 0rem 0.6rem;
}
.mrg_y_6{
  margin: 0.6rem 0rem;
}
.mrg_y{
  margin: 1rem 0rem;
}
.mrg_lt{
  margin-left: 0.6rem;
}
.user_segment_grid{
  grid-template-columns: 1fr 1fr 2fr 1fr;
}
.brd_btm{
  border-bottom: 1px solid #ccc;
}
.pd_cell{
  padding: 0.4rem 0.6rem;
}
.feed_detail_grid{
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.separator{
  border-top: 2px solid #B3B3B3;
  margin: 1.6rem 0;
}
.separatorText{
  top: -10px;
  left: 48%;
  color: #B3B3B3;
  padding: 0 0.4rem;
}

.thead{
  border: 1px solid #ccc;
  background-color: #eee;
  border-radius: 6px;
  margin-bottom: 0.6rem;
}
.brdBottom{
  border-bottom: 1px solid #ccc;
}
.gridColumn{
  grid-template-columns: 1fr 2.5fr;
}

/* custom scroll bar for navbar only */

/* width */
.inputContainer ::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.inputContainer ::-webkit-scrollbar-track {
  background: #f9f8f8; 
  margin: 2px 0;
  border-radius: 5px;
}
 
/* Handle */
.inputContainer ::-webkit-scrollbar-thumb {
  background: #ccc; 
  border-radius: 5px;
}

/* Handle on hover */
.inputContainer ::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc; 
}

.outlinedBtn {
  background-color: #fff;
  color: #2a9d8f;
  border: 1px solid #fff;
  outline: 1px solid #2a9c8d;
  font-weight: bolder;
  padding: 0.6rem 1.8rem;
  margin-left: 5px;
  border-radius: 25px;
  font-size: 0.9rem;
  
}
.container {
  padding: 1.6rem 1.8rem 2.4rem 1.8rem;
}
 
/* ***********  add widget  ************  */
.popupList{
  padding:0.8rem 0.5rem;
  border-bottom:1px dashed #c2c2c2;
  text-align: left;
}
.popupGrid{
  grid-template-columns: 0.2fr 1fr 1fr ;
}

.flex_row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.flex_col {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.justify_center {
  justify-content: space-between;
  width: 90%;
}
.flex2 {
  flex: 2;
  word-break: break-word;
}
.flex1 {
  flex: 1;
  overflow: hidden;
}

.pdX {
  padding: 0 10px;
}

.red_color {
  color: red;
}