.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* custom common css */

:root {
  --ondc-blue: #0d91d5;
  --ondc-blue-100: #0d91d50d;
  --ondc-blue-200: #0d91d520;
  --ondc-blue-300: #0d91d524;
  --ondc-blue-400: #0d91d530;
  --theme-green: #2a9d8f;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: #444;
}
button {
  cursor: pointer;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.printIcon {
  height: 0.9rem;
  margin-bottom: -0.15rem;
}
.printIcon.white path {
  fill: white;
}
.fontXS {
  font-size: 0.7rem;
}
.fontS {
  font-size: 0.8vw;
  font-weight: 420;
}
.fontM {
  font-size: 1.1rem;
  font-weight: 550;
}
.fontL {
  font-size: 1.4rem;
  font-weight: 450;
}
.bold {
  font-weight: 600;
}
.bg_white {
  background-color: white;
}
.green {
  color: #2a9d8f;
}
.yellow {
  color: #ffae00;
}
.red {
  color: #ef0e0e;
}
.lred {
  color: #f25e5e;
}
.black {
  color: #333;
}
.blue {
  color: #1566de;
}
.ondcBlue {
  color: var(--ondc-blue);
}
.ondcBlueIcon path {
  fill: var(--ondc-blue);
}
.grey {
  color: #ccc;
}
.orange {
  color: #ff6a00;
}
.lightBgYellow {
  background-color: #fffedf !important;
}
.lightBgGreen {
  background-color: #dff6e5 !important;
}
.lightbgBlue {
  background-color: #e8f5ff !important;
}
.whiteBg {
  background-color: #fff;
}
.pointer {
  cursor: pointer;
}
.not_allowed {
  cursor: not-allowed;
}
.default {
  cursor: default;
}
.labelGrey {
  color: #707683;
}
.labelGrey path {
  fill: #999 !important;
}
.labelGrey button {
  color: #999;
  border: 1px solid #999;
}
.disabled path {
  fill: #ccc;
}
.greyIcon path {
  fill: #b4b0b0;
}
.greyBdr {
  border: 1px solid #dddddd;
}
.greyBdrBtm {
  border-bottom: 1px solid #dddddd;
}
.greyBdrLeft {
  border-left: 1px solid #a2a2a2;
}
.greyBdrBtm {
  border-bottom: 1px solid #dddddd;
}
.breadCrump {
  text-decoration: underline;
  cursor: pointer;
}
.navIcon {
  height: 22px;
  margin-right: 22px;
  margin-top: -5px;
}
.visibility_hidden {
  visibility: hidden;
}
.dropdown_dummy_div {
  width: 100%;
  height: 100%;
  position: fixed;
  /* z-index: 1; */
  /* background-color: #f00; */
  left: 0;
  top: 0;
}
.boxShadow {
  box-shadow: 0px 0px 2px #009688;
}
.flex {
  display: flex;
  align-items: center;
}
.flexStretch {
  display: flex;
  align-items: stretch;
}
.flex-end {
  display: flex;
  align-items: flex-end;
}
.flex_start {
  display: flex;
  align-items: flex-start;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-space-around {
  justify-content: space-around;
}
.flex-justify-center {
  justify-content: center;
}
.flex-space-evenly {
  justify-content: space-evenly;
}
.flex-direction-col {
  flex-direction: column;
}
.textRight {
  text-align: right;
}
.textLeft {
  text-align: left;
}
.textCenter {
  text-align: center;
}
.grid {
  display: grid;
  align-items: center;
}
.grid-end {
  display: grid;
  align-items: end;
}
.grid-start {
  display: grid;
}
.grid-justify-center {
  justify-items: center;
}
.grid-align-start {
  align-items: start !important;
}
.align-item-stretch {
  align-items: stretch;
}
.formWrapper {
  padding: 1.6rem 2vw 3.5rem 2vw;
}

.upperCase {
  text-transform: uppercase;
}
.alignIcon {
  vertical-align: bottom;
}
.middleAlignIcon {
  vertical-align: middle;
}
.errorMessage {
  color: #d50000;
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 5px;
  font-family: OpenSans-Italic;
}
.iconLightGrey path {
  fill: #bbb;
}
.whiteIcon path {
  fill: #fff;
}
.red path {
  fill: #ef0e0e;
}
.redIcon path {
  fill: #a50707;
}
.greenIcon path {
  fill: #2a9d8f;
}
.blackIcon path {
  fill: #000;
}
.iconAlign {
  margin-bottom: -5px;
}
.textCapitalise {
  text-transform: capitalize;
}
.textUnderline {
  text-decoration: underline;
}
.textEllipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.textWordWrap {
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.icon20 {
  width: 20px;
  height: 20px;
  margin: 0 8px;
}
.icon24 {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-bottom: -8px;
}
.icon28 {
  width: 28px;
  height: 28px;
}
.iconM {
  width: 1.8vw;
  height: 1.8vw;
}
.rotate180 {
  transform: rotate(180deg);
}
.separatorDashed {
  border-bottom: 2px dashed #ccc;
}
.separatorDotted {
  border-bottom: 2px dotted #ccc;
}
.rightFloat {
  float: right;
}
.brdRdS {
  border-radius: 5px;
}
.mrg-r-2 {
  margin-right: 1rem;
}
.mrg-r-1 {
  margin-right: 0.5rem;
}
.my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/*    pagination    */

.pageBtnContainer {
  position: fixed;
  bottom: 15px;
}
.pageBtn {
  border-radius: 5px;
  padding: 0.6rem 1.2rem;
  margin-right: 0.4rem;
}
.outlinedBtn {
  background: white;
  border: 1px solid #2a9d8f;
  color: #2a9d8f;
}
.outlinedBtn:disabled {
  color: #bbbbbb;
  background-color: #ffffe9;
  border-color: #bbbbbb;
  cursor: not-allowed;
}
.filledBtn {
  background: #2a9d8f;
  border: 1px solid #2a9d8f;
  color: #fff;
}
.filledBtn:disabled {
  background: #ccc;
  border: 1px solid #ddd;
  color: #fff;
  cursor: not-allowed;
}

/*    container   */

.whiteContainer {
  background-color: #fff;
  border-radius: 12px;
  padding: 1.8rem 0;
  color: #423d3d;
}

.whiteContainerSpacing {
  margin: 1.2rem 1.8rem;
  padding: 1.8rem 1.4rem;
}

.minHeight70 {
  min-height: 70vh;
}

.mainHeading {
  background-color: #EBF0F5;
  border-radius: 10px;
  margin: 0 1.8vw 1.2vw 1.8vw;
  padding: 0.6rem 0.8rem;
}

.filterContainerMrg {
  margin: 1.2rem 1.6rem;
}

.noResize {
  resize: none;
}

/* Hide the clear button */
input[type="date"]::-webkit-clear-button {
  display: none;
}

/* custom css */


.gap_10 {
  gap: 10px;
}

.flex_col {
  display: flex;
  flex-direction: column;
}

.greywrapper {
  margin-left: 0.3rem;
  border-radius: 8px 8px 8px 8px;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  background: #eef3f8;
  padding: 0.8rem 1rem;
  padding-bottom: 1rem;
}

.greyHeader {
  margin-top: 1rem;
  margin-left: 0.3rem;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  background: #d9e4ef;
  padding: 0.5rem 1rem;
  color: #423d3d;
  margin-bottom: 0;
}
