.header{
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
  }
.header_title{
   margin-left: 1.5rem;
}
.mrgRgh{
  margin-right: 0.8rem;
}
