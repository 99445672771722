/* ***********  header css  ************  */

.header{
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
  }
.header_title{
   margin-left: 4.5rem;
}

/********** kafka switcher listing********/
.mrg_x_6{
    margin: 0rem 0.6rem;
}
.pd_bg{
    padding: 1rem 0.8rem
}
.container{
    padding: 1.6rem 1.8rem 2.4rem 1.8rem;
    background-color: #F1FFFC;
    min-height: 100vh;
}
.thead{
    background-color: #2a9d8f;
    color: white;
    /* font-weight: bold; */
    /* padding: 16px; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-transform: uppercase;
}
.tbody{
    border: 1px solid #e1e1e1;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #e5e5e5;
    /* font-size: 1.2rem; */
}
.trow{
    /* color: #555; */
    border-bottom: 1px solid #ececec;
}
.trow:last-child{
    border-bottom: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.trow:nth-child(odd){
    background-color: #F1FFFC;

}
.trow:nth-child(even){
 background-color: white;
}
.width33{
    width: 33%;
    text-align: center;
    font-family: sans-serif;
}

.conditionalInput{
    width: 100px;
    padding: 0.4rem;
}
.filledBtn{
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
}
.closeIcon{
    width: 30px;
    height: 30px;
    margin-left: 20px;
    margin-bottom: -10px;
}
