.bg-green {
    background-color: #009688;
}

.white-text {
    color: #fff;
}

.ds_flex {
    display: flex;
}

.col-md-4 {
    width: 30%;
}

.d-none {
    display: none;
}

.mr-l20 {
    margin-left: 20px;
}

.coupon-table-body {
    margin-top: 90px;
    width: calc(100% - 30px);
}

/* .fullwiimport NavBar from '../../CommonService/Header/NavBar';
dth{float: left; width: 100%;} */
/********* table *********/
.table-wrapp {
    padding: 16px 15px 0;
    float: left;
    width: 98%;
}

.tbl-body {
    background-color: #fcfcfc;
    float: left;
    width: 100%;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 5px;
    margin-top: 60px;
}

.table-bdy {
    width: 100%;
}

.tbl-hd-fixed {
    position: fixed;
    top: 57px;
    padding: 0;
    z-index: 10;
    font-weight: 600;
    width: calc(100% - 30px);
}

.tbl-hd {
    padding: 0;
    font-weight: 600;
    width: 100%;
}

.media-hd-fix {
    width: calc(100% - 60px);
}

.tbl-row {
    width: 100%;
    float: left;
    padding: 15px 0;
    position: relative;
}

.tbl-row>a {
    font-weight: normal;
}

.tbl-row>a:hover {
    color: #727272;
}

.tbl-body .tbl-row:nth-child(even) {
    border-style: solid;
    border-width: 1px 0 1px 0;
}

.tbl-body .tbl-row.bg-yellow_new {
    background-color: #FFFF00;
}

.tbl-body .tbl-row {
    background-color: #fcfcfc;
}

.tbl-body .tbl-row:hover:not(.bg-yellow_new),
.tbl-body .tbl-row:hover .as-legend {
    background-color: #f8f8f8;
}

.icon {
    width: 24px;
    height: 24px;
    /* background-size: 100%; */
    float: left;
    margin-right: 10px;
    cursor: pointer;
}

.icon-deactivate {
    /* background: url(../../../Images/ic_deactivate.svg) no-repeat center center; */
}

.icon-activate {
    /* background: url(../../../Images/ic_active.svg) no-repeat center center; */
}

.bdr-grey {
    border-color: #d8e1e0;
}

.frm-detail {
    padding-bottom: 60px;
}

.bdr {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
}

.bg-white {
    background-color: #fcfcfc;
}

.hdr_title {
    float: left;
    margin: 0 25px 0 0;
    width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 20px 15px 14px;
}

.datepicker {
    border: 1px solid rgb(221, 221, 221);
    font-size: 15px;
    margin-top: 5px;
    height: 45px;
    text-align: center;
    outline: none;
    background: none;
    border-radius: 10px;
    width: 200px;
}

.frm_title {
    border-style: solid;
    border-width: 0 0 1px 0;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    float: left;
    margin-bottom: 15px;
    margin-top: 15px;
    width: 96%;
}

.frm-detail span {
    color: #727272;
}

.bdr-dgrey {
    border-color: #212121;
}

.fullwidth {
    float: left;
    width: 100%;
}

.green-btn {
    background-color: #009688;
}

.pull-right {
    float: right !important;
}

.form-group {
    margin-bottom: 15px;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

.control-label {
    font-size: 10px;
    font-weight: 600;
    color: #90aea2;
    text-transform: uppercase;
}

.col_xs_3 {
    width: 25%;
}

.form-control {
    display: block;
    height: 37px;
    padding: 6px 16px;
    font-size: 13px;
    line-height: 1.846;
    color: #666666;
    background-color: transparent;
    background-image: none;
    border: none;
    border-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    font-size: 14px;
    border: 1px solid transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #ddd;
    outline: none;
}

.form-control:focus {
    /* border-color: none; */
    border: none;
    border-bottom: 1px solid #009688;
    border-color: #009688;
    box-shadow: inset 0 -2px 0 #009688;

}

.hide {
    display: none;
}

.col-xs-offset-2 {
    margin-left: 16.66666667%;
}

.col-xs-8 {
    width: 66.66666667%;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    float: left;
}

.fixed-tbl-hd-pd {
    padding-top: 38px;
    background-color: #f2f1ee;
    height: 94vh;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 16px;
    font-size: 13px;
    line-height: 1.846;
    border-radius: 3px;
    margin-bottom: 20px;
    margin-right: 15px;
    text-transform: uppercase;
    border-radius: none;
    box-shadow: none;
    color: #fff;
}

.coupon-container {
    width: 100%;
    display: flex;
}

.col-xs-3 {
    width: 40%;
}

.form-body {
    height: 250px;
    width: 40vw;
    border: none;
    border-radius: 10px;
    box-shadow: 3px 3px 7px rgb(33, 37, 41)
}

.search-catagory {
    margin-top: 10px;
    width: 235px;
    padding: 10px;
    border: 1px solid lightgray;
    margin-left: -11px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    outline: none;
}

.search-bar {
    display: flex;
    margin-left: 25px;
    margin-top: 5px;
}

.search-image {
    width: 38px;
    transform: translate(-25%, 25%);
    margin-left: 10px;
    border: 1px solid lightgray;
    height: 38px;
    background: white;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px,
}

.create-campaign-btn {
    margin-top: 9px;
    font-size: 14px;
    font-weight: 600;
    width: 145px;
}

.popup-heading {
    display: inline-block;
    margin-top: 5px;
    font-size: 20px;
}

.ds_flex {
    display: flex;
}

.col_md_2 {
    width: 16.6%
}

.col_md_3 {
    width: 25%;
}

.icon-edit-grey {
    /* background: url(../../../Images/edit_pencil.svg); */
}

.media-object {
    width: 70px;
    height: 70px;
}

/* .banner_container {
    margin-left: 25px;
    margin-top: 5px;
} */

.header_width {
    width: 35%;
}

.full_width {
    /* width: 100%; */
    display: flex;
}

.col_xs_4 {
    width: 33.33333333%;
    float: left;
}

.camp_mr_left {
    margin-left: 130px;
    margin-top: 20px;
}

.col_xs_2 {
    width: 16.66666667%;
    float: left;
}

.col_xs_2 input {
    width: auto;
}


.media {
    display: grid;
    grid-template-columns: 1fr 3.5fr;
}

.red-text {
    color: red;
    margin-top: 10px;
}

.calIcon {
    z-index: 1;
    position: relative;
    top: 4px;
    left: 55px;
}

.tgreen-text {
    color: green;
    font-weight: 600;
}

.status_cont {
    margin-top: 18px;
}


/* New Fresh Css starts */

.bonus_managementtable_header_container {
    /* margin-top: 2.8%; */
    background-color: #379733;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr;
    padding: 15px 0px;
    color: white;
    font-weight: 600;
    width: calc(100% - 50px);
    text-align: center;
    float: left;
    position: fixed;
    left: 28px;
    box-shadow: 3px 3px 10px 3px rgb(0 0 0 / 15%);
}

.bonus_managementtable_body_container {
    border: 1px solid black;
    position: absolute;
    top: 80px;
    z-index: -1;
    width: calc(100% - 50px);
    float: left;
    left: 28px;
    border: 1px solid #dbc8c8;
    border-radius: 10px;
    box-shadow: 3px 3px 10px 3px rgb(0 0 0 / 15%);
    overflow: auto;
}

.bonus_managementtable_body {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr;
    font-weight: 600;
    text-align: center;
}

.bonus_managementtable_item {
    border-bottom: 1px solid #d8e1e0;
    padding: 25px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-color {
    background-color: #379733;
    margin: 10px;
}

.table_item img {
    height: 20px;
    padding: 0px 5px;
}

.lower-btn-container {
    display: flex;
    position: relative;
    top: 78vh;
    justify-content: center;
    margin: 0px 35px;
}

/* .feed-container {
    position: relative;
    top: 53px;
    width: 98.7vw;
    height: 93vh;
    z-index: 1;
} */

/*for feed campaign css starts from here*/

.heading_container_feed {
    margin: 30px 70px 30px 70px;
    background: rgb(235 240 245);
    border-radius: 10px;
    padding: 10px;
}

.heading_text_feed {
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
}

.inputFeild_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-left: 150px;
}

.input_field input {
    height: 37px;
    padding: 6px 16px;
    border: none;
    border-bottom: 1px solid gray;
    font-size: 14px;
}

.input_label_text {
    padding: 10px 5px;
    font-weight: 700;
    max-width: 100%;

}

.dropdown_container_feedForm {
    display: grid;
    grid-template-columns: 20% 20% 20%;
    margin-left: 145px;
    margin-top: 50px;
}

.img_container {
    margin-top: 25px;
}

.input_file {
    margin-top: 10px;
}

.image_container {
    display: flex;
    border: 1px solid black;
    width: 100px;
    height: 100px;

}

.dropdown_feed {
    display: grid;
    grid-template-columns: 20% 20%;
    margin-left: 145px;
    margin-top: 75px;
    justify-self: center;
}

.upload_btn_feed {
    border: none;
    width: 200px;
    border-radius: 8px;
    cursor: pointer;
    background-color: #379733;
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    /* width: 130px; */
    display: block;
    padding: 12px;
}

.dropdown_label {
    padding: 10px;
}

.add_widget_btn {
    border: none;
    width: 300px;
    border-radius: 8px;
    cursor: pointer;
    background-color: #379733;
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-top: 95px;
    /* width: 130px; */
    display: block;
    padding: 15px;
}

.add_widget_container {
    margin-left: 150px;
    margin-top: 60px;
}

.bonus_managementimg {
    height: 21px;
    width: 21px;
    margin: 10px;
}

.BonusManagement_checkbox {
    margin: 5px;
    width: 16px;
    height: 16px;
    accent-color: #2a9d8f;
}


.scheduleModal_heading {
    margin: 20px 25px;
    background: rgb(235 240 245);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scheduleModal_smsText_pnText_input_container {
    margin-top: 30px;
    width: 100%;
}

.scheduleModal_heading {
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
}

.scheduleModal_text_label {
    font-size: 16px;
    font-weight: 500;
}

.scheduleModal_sms_input {
    padding: 15px;
    border: none;
    width: 50%;
    border-bottom: 1px solid black;
}

.scheduleModal_pn_input {
    padding: 15px;
    border: none;
    width: 50%;
    border-bottom: 1px solid black;
}

.scheduleModal_parent_conteiner {
    padding: 10px 50px;
}

.scheduleModal_smsText_container {
    margin-bottom: 30px;
}

.scheduleModal_btn_container {
    margin-top: 30px;
    display: flex;

}

.bonus_management_input_feild {
    padding: 10px 0px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid gray;
}

.Bonus_management_fixed_footer_btn {
    position: fixed;
    bottom: 0px;
    padding: 15px;
}

.bonus_management_schedule_icon {
    color: #379733;
    height: 30px;
    cursor: pointer;
}

.Add_Segement_form_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px 75px;
}

.Add_campaign_input_item {
    padding: 15px;
    border: none;
    width: 50%;
    border-bottom: 1px solid black;
}

.Add_Segement_input_upper_container{
    margin-bottom: 50px;
}

.bonus_management_header_btn_container{
    display: flex;
    justify-content: space-between;
    flex: 1;
}


.upper_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.image_container_bonus_management{
    margin-bottom: 20px;
}

.whatsapp_template_container{
    padding: 10px 50px;
}

.vars_list_container{
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 50%;
}

.var_list_item{
    padding: 25px;
    
}

.vars_list_display_container{
    margin-top: 20px;
}