/* header css */

.header {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0.6rem 1.6rem;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 1px 4px #ccc;
  min-height: 2.4rem;
}
.header_title {
  margin-left: 4.5rem;
  margin-top: 0.5rem;
}

/* common CSS*/

.pd_bg {
  padding: 1rem 0.8rem;
}
.pd_bg1 {
  padding: 1rem 0.4rem;
}
.mrg_tp_3 {
  margin-top: 3rem;
}
.mrg_tp_6 {
  margin-top: 6rem;
}
.mrg_x_6 {
  margin: 0rem 0.6rem;
}
.width25 {
  width: 25%;
}
.width40 {
  width: 40%;
}
.width50 {
  width: 50%;
}

/* Dropdown CSS*/

.dropdown {
  min-width: 200px;
  display: inline-block;
}

/*Buttons CSS*/

.btnM {
  min-width: 9rem;
}
.filledBtn {
  background-color: #2a9d8f;
  color: #fff;
  border: 1px solid #2a9d8f;
  font-weight: bolder;
  padding: 0.6rem 1.8rem;
  margin-left: 5px;
  border-radius: 5px;
  font-size: 0.9rem;
}
.outlinedBtn {
  background-color: #fff;
  color: #2a9d8f;
  border: 1px solid #2a9d8f;
  font-weight: bolder;
  padding: 0.6rem 1.8rem;
  margin-left: 5px;
  border-radius: 5px;
  font-size: 0.9rem;
  
}

/* Banner Listing CSS*/

.bannerImg{
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 5px;
}
.container {
  padding: 1.6rem 1.8rem 2.4rem 1.8rem;
  background-color: #F1FFFC;
  min-height: 100vh;
}
.thead {
  background-color: #2a9d8f;
  color: white;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-transform: uppercase;
}
.tbody {
  margin: 0;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #e5e5e5;
}
.trow:last-child {
  border-bottom: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.trow:nth-child(odd) {
  background-color: #F1FFFC;
}
.trow:nth-child(even) {
  background-color: white;
}

/*Order Banner*/
.subHeading {
  margin: 1.2rem 2rem;
  padding: 0.9rem;
  text-align: center;
  background-color: #e4fffc;
  border-radius: 10px;
}
.whiteContainer {
  margin: 1rem 2rem 6rem 2rem;
  padding: 1.4rem 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 3px 4px #eee;
}
.order_banner_zone_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.inputField {
  padding: 0.6rem;
  border-radius: 6px;
  border: 1px solid #fff;
  outline-style: auto;
  outline-color: #2a9d8f;
}
.list_item {
  width: 33%;
  margin-top: 30px;
  min-width: 210px;
}

.checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  appearance: none;
  -webkit-appearance: none;
  margin-bottom: -4px;
  margin-right: 10px;
  accent-color: #2a9d8f;
  outline: 2px solid #2a9d8f;
  /* cursor: pointer; */
}

.checkbox:checked{
  background-color: #2a9d8f;
  background-image: url('../../../../icons/check-mark.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.checkbox:disabled{
  outline: 2px solid #ccc;
  background-color: #c1c1c1;
}

.isActiveCheckbox {
  display: inline-block;
  background-color: #e4fffc;
  color: #2a9d8f;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.9rem 1.4rem 0.9rem 0.9rem;
  border: 1px solid #2a9d8f;
  accent-color: #2a9d8f;
  border-radius: 8px;
}

.isActiveCheckbox > input {
  margin-right: 20px;
  display: inline-block;
  width: 18px;
  height: 18px;
}
.dropdown_menu {
  margin-top: 40px;
  margin-left: -2px;
}
.land_page {
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  color: #2a9d8f;
  margin-bottom: 9px;
}

.imgContainer {
  width: 400px;
  height: 150px;
  margin-top: 0.2rem;
}
.img_Container {
  display: flex;
  justify-content: space-between;
}
.uploadImg {
  margin: 1.8rem 0.6rem;
}
.title_banner {
  margin: 0.6rem 0;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #2a9d8f;
}
.uploadBtn {
  background-color: #fff;
  padding: 0.6rem 0.8rem;
  font-weight: bold;
  color: #2a9d8f;
  margin-left: 50px;
  outline: none;
  border: 1px solid #f2f2f2;
  outline: 2px solid #2a9d8f;
  border-radius: 4px;
  width: 100px;
}
