.backDiv {
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0.507);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20;
}
.popup {
  position: fixed;
  z-index: 30;
  top: 5rem;
  /* left: 29vw; */
  /* width: 38vw; */
  text-align: center;
  background: white;
  border: 1px solid #969595;
  border-radius: 0.8rem;
  padding: 0.8rem 1rem;
}
.flexSpaceBw {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.heading {
  margin: 0;
  padding: 0.6rem 0.6rem;
  /* box-shadow: 0px 5px 5px 0px rgb(233 233 233); */
  border-bottom: 1px solid #c2c2c2;
}
.bagDetails {
  padding: 0 0.6rem;
  text-align: left;
}
.btnContainer button {
  margin-top: 0.6rem;
  padding: 0.4rem 1rem;
  margin-left: 2.5rem;
  border-radius: 0.4rem;
  border: 1px solid #979797;
  font-size: 0.9rem;
  opacity: 0.68;
}
.btnContainer button:hover {
  opacity: 1;
}
.btnContainer {
  text-align: right;
}
.succesBtn {
  color: white;
  background-color: #009688;
}
.capital{
  text-transform: uppercase;
}