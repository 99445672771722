.dropdown-menu{
    height: 35px;
    width: 150px;
    border: 1px;
    border-radius: 5px;
    outline: none;
    align-content: center;
    text-align: center;
    font-size: 0.9vw;
    font-weight: 600;
    min-width: 300px;
}

.option{
    cursor: pointer;
    padding: 0.4rem 0.6rem;
    appearance: none;
    outline: 0;
    border-bottom: 1.5px solid rgb(221 221 221);
    font-size: 0.9vw;
}

.option:hover{
    background-color: #ececec;
}

.textleft {
    text-align: left;
}

.list{
    position:absolute;
    z-index: 1;
    list-style: none;
    text-align: center;
    padding: 5px 0px;
    margin-top: 0;
    box-shadow: 1px 1px 4px #ccc;
    background: white;
    border-radius:  8px;
    overflow-y: auto;
    width: 100%;
}

.dropdown_header{
    border: 1px solid #f8f8f8;
    background-color: #fff;
    outline-style: auto;
    /* outline-color: #2a9d8f; */
    /* max-height: 25px; 
    width: 180px; */
    padding: 0.6rem;
    min-width: 150px;
    text-align: center;
    font-size: 0.9vw;
    text-transform: capitalize;
    border-radius: 5px;
    /* cursor: pointer; */
}

.greenOutline{
    outline-color: #2a9d8f;
}

.ondcOutline{
    outline-color: var(--ondc-blue);
}

.dropdown_header_disabled{
    outline-color: #ccc;
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
    cursor: not-allowed;
}
.dropdown_container{
    display: flex;
    flex-direction: column; 
    margin-right: 10px;
    position: relative;
}

.seachInp {
    width: 85%;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
}

.text_container{
    text-overflow: ellipsis;
     /* float: left;  */
    white-space: nowrap;
    overflow: hidden;
}


/* custom scroll bar for navbar only */

/* width */
.dropdown_container ::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
.dropdown_container ::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
.dropdown_container ::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 8px;
  }
  
  /* Handle on hover */
.dropdown_container ::-webkit-scrollbar-thumb:hover {
    background: #bcbcbc; 
  }