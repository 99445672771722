.header {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
}

.header_title {
    margin-left: 1.5rem;
}
ul, li{
    padding: 0;
}
.pull_right{
    float:right;
}
.fullWidth{
    width: 100%;
}
.width80{
    width: 80%;
}
.width53{
    width: 53%;
}
.width35{
    width: 35%;
}
.mrg_x_6 {
    margin: 0rem 0.6rem;
}
.mrg_btm_3{
    margin-bottom: 3rem;
}

/*Buttons CSS*/
.varNum{
    border: 2px solid #2a9d8f;
    border-radius: 5px;
    padding: 0.44rem 0.6rem;
}
.csvBtn{
    margin-left: 30px !important;
}
.csvBtn img{
    margin: -10px 20px -10px -10px;
}

.btnM {
    min-width: 9rem;
}

.filledBtn {
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
}

.filledBtn:disabled{
    border: 1px solid #999;
    background-color: #ccc;
    cursor:not-allowed;
}

.outlinedBtn {
    background-color: #fff;
    color: #2a9d8f;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 0.9rem;

}
/* Banner Listing CSS*/
.pd_bg {
    padding: 1rem 0.8rem;
}

.pd_bg1 {
    padding: 1rem 0.4rem;
}

.container {
    padding: 1.6rem 1.8rem 2.4rem 1.8rem;
    background-color: #F1FFFC;
    min-height: 100vh;
}

.thead {
    background-color: #2a9d8f;
    color: white;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-transform: uppercase;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 0.5fr;
}

.tbody {
    border: 1px solid #e1e1e1;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #e5e5e5;
}

.trow {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 0.5fr;
    border-bottom: 1px solid #ececec;

}

.trow:last-child {
    border-bottom: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.trow:nth-child(odd) {
    background-color: #F1FFFC;
}

.trow:nth-child(even) {
    background-color: white;
}

.width_50 {
    width: 50%;
}

.width_50 button {
    width: calc(100% - 40px);
}

.title {
    margin: 0.6rem;
    text-transform: uppercase;
    font-size: 0.8rem;
}

/*Order Banner*/
.createWidget {
    margin: 1.1rem 0;
    padding: 0.9rem;
    text-align: center;
    background-color: #e4fffc;
    border-radius: 10px;
}

.addContainer{
    margin: 15px 0;
    border: 1px solid #ccc;
    box-shadow: 0px 3px 4px #eee;
    border-radius: 8px;
    padding: 1.4rem 2rem;
}


.checkbox {
    display: inline-block;
    width: 18px;
    height: 18px;
    -webkit-appearance: none;
    appearance: none;
    margin-bottom: -4px;
    margin-right: 10px;
    accent-color: #2a9d8f;
    outline: 2px solid #2a9d8f;
    /* cursor: pointer; */
}

.checkbox:checked {
    background-color: #2a9d8f;
    background-image: url('../../../../icons/check-mark.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.mrg_top_3 {
    margin-top: 3rem;
}

.list_item {
    width: 25%;
    margin-top: 30px;
    min-width: 210px;
    text-transform: capitalize;
}
.wd_50p{
    width: 50%;
}

.input_field {
  padding: 0.4rem 0.6rem;
  border: none;
  border-bottom: 2px solid #ccc;
  /* margin-right: 1.8rem; */
  outline: none;
}

input.input_field:disabled {
  background-color: rgba(0,0, 0,0);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 2px solid #e2e2e2;
  cursor: not-allowed;
  color: #ccc;
}

.input_field:focus, .input_field:focus-visible {
  border-bottom: 2px solid #2a9d8b;
}  

.searchInput {
    border: none;
    padding: 6px;
    font-size: 0.9rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #2a9d8f;
    outline: none;
}

.searchIcon {
    background: #2a9d8f;
    padding: 3px;
    border: 1px solid #2a9d8f;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.land_page {
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    color: #2a9d8f;
    margin-bottom: 9px;
}

.separator{
    border-bottom: 1px dashed #ccc;
    padding-bottom: 1.6rem;
}

.imgContainer {
    width: 400px;
    /* height: 150px; */
    margin-top: 0.2rem;
}

.uploadBtn {
    background-color: #fff;
    padding: 0.6rem 1.6rem;
    font-weight: bold;
    color: #2a9d8f;
    outline: none;
    border: 1px solid #2a9d8f;
    border-radius: 4px;
    margin-top: 6px;
}

.ht_wd_70 {
    height: 70px;
    /* width: 185px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}
.mr_t_30{
    margin-top: 30px;
}

.temp_col {
    grid-template-columns: 1fr 3.5fr;
}

.grid_templete2{
    grid-template-columns: 1fr 1fr;
}
.grid_template5{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.grid_template4{
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.grid_template_sku{
    grid-template-columns: 1fr 4fr 1fr 1fr;
}
.variable_wrapper{
    padding: 10px;
    border: 1px dotted #ccc;
    margin-top: 30px;
    border-radius: 5px;
}
.variable_list{
    grid-template-columns: 0.7fr 1fr 1fr 1fr 0.3fr;
}
.variable_title{
    color: #2a9d8f;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid #2a9d8f;
}

.custome_date_time{
    padding: 6px;
    /* width: 12%; */
    border: none;
    outline: none;
    border-bottom: 2px solid #ccc;
    width: 100%;
    margin-left: 5px;
    font-size: 0.9rem;
    }

.height_width_30px{
    height: 30px;
    width: 30px;
}

/* ------------ video and imgae css -------------*/
.greyBrd {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}
.imgContainer {
    width: 66%;
    margin: 12px 10px;
}
.imgUpload {
    width: 100%;
    height: 250px;
    background-color: #F3F9FF;
    border-bottom: 1px solid #ccc;
}
.imgPlaceholder {
    padding: 12px 6px;
    border: 1px solid #2a9d8f;
    background-color: #FFF;
    border-radius: 5px;
}
.uploadVideo{
    border: 1px solid #009688;
    padding: 2px 8px 0px 8px;
    border-radius: 5px;
}