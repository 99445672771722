/* ***********  header css  ************  */

.header{
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
  }
.header_title{
   margin-left: 4.5rem;
}
/**************Listing***********/

.pd_bg{
    padding: 1rem 0.9rem;
}
.container{
    padding: 1.6rem 1.8rem 2.4rem 1.8rem;
    background-color: #F1FFFC;
    min-height: 100vh;
}
.thead{
    background-color: #2a9d8f;
    color: white;
    display: grid;
    font-size: 13px;
    grid-template-columns: 1.3fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

}

.tbody{
    border: 1px solid #e1e1e1;
    margin: 0;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #e5e5e5;
    /* font-size: 1.2rem; */
}
.trow{
    /* color: #555; */
    display: grid;
    grid-template-columns: 1.3fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid #ececec;
    text-align:left;
    font-family: sans-serif;
    font-size: 0.8rem;
}
.trow:last-child{
    border-bottom: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.trow:nth-child(odd){
    background-color: #F1FFFC;

}
.trow:nth-child(even){
 background-color: white;
}

.datepicker{
    padding: 8px;
    border: none; 
    background-color: #fff; 
    font-size: 15px;
}
.calender{
    background-color: #fff;
    padding: 8px;
}