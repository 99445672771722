/* * {
    font-family: Open Sans,sans-serif;
} */

/* *********** Form css  *********** */
.btnM{
    min-width: 9rem;
}
.filledBtn{
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
}
.filledGreyBtn{
    background-color: #ccc;
    color: #fff;
    border: 1px solid #ccc;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
}
.smallFilledBtn {
    background: #2a9d8f;
    border: 1px solid #2a9d8f;
    border-radius: 6px;
    color: #fff;
    margin-left: 10px;
    padding: 5px;
}
.overlay12{
    height: 12rem;
    overflow: overlay;
}
.dropdown_dummy_div {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}
.dropdown{
    min-width:200px;
}
.dropdownMenu{
    background-color: #fff;
    border: 1px solid #f8f8f8;
    outline-style: auto;
    outline-color: #2a9d8f;
    padding: 0.6rem;
    min-width: 200px;
    border-radius: 5px;
    position: relative;
    text-transform: capitalize;
    font-size: 0.9vw;
    width: 86%;
    color: rgb(24, 24, 24);
}
.dropdownMenu:disabled{
    outline-color: #ccc;
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
    cursor: not-allowed;
}
.dropdownList{
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid lightgray;
    border-radius: 9px;
    min-width: 200px;
    position: absolute;
}
.dropdownList li{
    background-color: white;
    border-bottom: 1px solid lightgray;
    padding: 9px 15px;
    text-transform: capitalize;
    font-size: 0.9vw;
}
.dropdownList li:first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
 }
 .dropdownList li:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
 }
 .dropdownList li:hover{
    background-color: #eef2f5;
    cursor: pointer;
 } 
 .mrgL{
    margin: 2.4rem 1.8rem;
 }
 .pdBtm{
    padding-bottom: 2.6vw;
 } 

/* ***********  header css  ************  */

.header{
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
    font-size: 1.1vw;
  }
.header_title{
   margin-left: 1.5rem;
}
.backIcon{
    width: 22px;
    height: 22px;
    margin-right: 8px;
    margin-bottom: -5px;
    cursor: pointer;
}
/* ************ Banner Listing css *********** */

.mrg_x_6{
    margin: 0rem 0.6rem;
}
.pd_bg{
    padding: 1rem 0.8rem
}
.container{
    padding: 1.6rem 1.8rem 4rem 1.8rem;
    background-color: #f1fffc;
    min-height: 100vh;
}
.thead{
    background-color: #2a9d8f;
    color: white;
    /* font-weight: bold; */
    /* padding: 16px; */
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-transform: uppercase;
}
.tbody{
    border: 1px solid #e1e1e1;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #e5e5e5;
    /* font-size: 1.2rem; */
}
.gridListing{
    grid-template-columns: 1fr 1fr 1fr 0.6fr 0.6fr 0.6fr;
}
.historyGridListing{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.trow{
    /* color: #555; */
    border-bottom: 1px solid #ececec;
}
.trow:last-child{
    border-bottom: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.trow:nth-child(odd){
    background-color: #f1fffc;

}
.trow:nth-child(even){
 background-color: white;
}
.width25{
    width: 25%;
    text-align:left;
    font-family: sans-serif;
}
.green{
    color: #2a9d8f;
    font-weight: 600;
}
.widgetBtn{
    background-color: #2a9d8f;
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    border: 1px solid #2a9d8f;
    border-radius: 5px;
    position: fixed;
    bottom: 30px;
    right: 10px;
    padding: 0.8rem;
}

/* **************  Popup css  ************** */
.popup{
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 3px;
    width: 40%;
    height: 260px;
    z-index: 20;
    position: absolute;
    top: 200px;
    left: 450px;
    text-align: center;
}
.firstLine{
   font-weight: 600;
   font-size: 1.2rem;
   padding-top: 50px;
   color: #242533;
   font-family: sans-serif;
}
.secondLine{
    font-weight: 600;
    font-size: 1.1rem;
    padding-top: 20px;
    color: #1b1b1b;
    font-family: sans-serif;
}
.btn{
    margin: 20px 18px;
    padding: 10px;
    width: 100px;
    border: 1px solid #009d8b;
    background-color: #fff;
    color: #009d8b;
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 4px;
}
.btn:hover{
    color: #fff;
    background-color: #009d8b;
}
.customPopup{
    background: rgba(0,0,0,.5);
    z-index: 20;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

/* *************  Widget screen *************  */

.widget_head_title{
    margin-left: 5rem;
}
.widget_container{
    padding: 1.6rem 1.8rem 2.4rem 1.8rem;
    flex-wrap: wrap;
}
.title{
    margin: 0.6rem;
    text-transform: uppercase;
    font-size: 0.8vw;
}
.createWidget{
    margin-bottom: 0.8rem;
    padding: 0.9rem;
    text-align: center;
    background-color: #e4fffc;
    border-radius: 10px;
}
.listingContainer{
    border: 1px solid #ccc;
    border-radius: 8px;
    padding-bottom: 2rem;
    box-shadow: 0px 3px 4px #eee;
    font-size: 1vw;
}

.firstBox{
    /* width:50%; */
    min-width: 250px;
}
.row{
    display: flex;
    margin-bottom: 2.6vw;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.width_7{
    width: 7%;
}
.width_10{
    width: 10%;
}
.width_15{
    width: 15%;
}
.width_20{
    width: 20%;
}
.width_25{
    width: 25%;
    min-width: 200px;
}
.width_30{
    width: 30%;
}
.width_33{
    width: 33%;
    min-width: 200px;
}
.width_50{
    width: 50%;
}
.width_50 > input, .width_50 button {
width: calc(100% - 40px);
}
.width100{
    width: 100%;
}
.inputField{
  font-size: 0.9vw;
  padding: 0.6rem;
  border-radius: 6px;
  border:1px solid #fff;
  outline-style: auto;
  outline-color: #2a9d8f;
  width: 70%;
}
.inputField:disabled{
  outline-color: #ccc;

}
.uploadImg{
    margin: 1.8rem 0.6rem;
}
.imgContainer{
    width: 100px;
    height: 100px;
    margin-top: 0.2rem;
    background: #eee;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
}
.uploadBtn{
    background-color: #2a9d8f;
    padding: 0.4rem 0.6rem;
    margin-left: 50px;
    color:#fff;
    border: 1px solid #2a9d8f;
    border-radius: 4px;
    width: 100px;
}
.submitBtn{
    border: 1px solid #2a9d8f;
    background-color: #2a9d8f;
    color: white;
    padding: 0.8rem 2.4rem;
    font-weight: 600;
    border-radius: 8px;
}
.textCenter{
    text-align: center;
}
.DeleteIcon{
    margin-left: 1.2rem;
}
.prodContainer{
    margin-top: 1rem;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
}
.headerSection {
    background-color: #F6FAFF;
    border-radius: 8px;
    padding: 2px 10px; 
}
.titlePd{
    padding: 0.4rem 0.6rem;
}
.prodListThead{
    background-color: #EBF0F5;
    border-radius: 8px 8px 0 0 ;
}
.prodList{
    background-color: #F8F8F8;
}
.gridContentData {
    grid-template-columns: 0.4fr 1.8fr 2fr 1.5fr 1.5fr 0.5fr;
}
.gridContentDataExtra {
    grid-template-columns: 0.4fr 1.5fr 1.8fr 2fr 1.5fr 1.5fr  0.5fr;
}
.addBtn{
    padding: 0.4rem 1.2rem;
    border-radius: 8px;
    outline: none;
    border: 2px solid #009D8B;
    background-color: transparent;
    color: #009D8B;
    font-size: 1rem;
}
.addBtn:disabled{
    border: 2px solid #999;
    color: #999;
    cursor: not-allowed;
}
.imgUploadBtn{
    background-color: #EBF0F5;
    color: #bbb;
    border: 1px solid #DEE2E9;
    border-radius: 4px;
    padding: 0.4rem 0.8rem;
    margin: 0.4rem 0;
}
.seeMore{
    margin-top: 2.6rem;
    margin-bottom: 1rem;
}
.sku_img{
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 4vw;
    height: 4vw;
    margin: 0.4rem;
}
.link{
    color: #2a9d8f;
    text-decoration: underline;
}
.textEllipsis{
    white-space: nowrap; 
    width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.inputRank{
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    padding: 5px;
    width: 80px;
}
.rankChange{
    background: #e3f0ff;
    border-radius: 4px;
    color: #0762ce;
    padding: 6px;
}
.imgUpload {
    padding: 12px 6px;
    border: 1px solid #2a9d8f;
    background-color: #FFF;
    border-radius: 5px;
}
