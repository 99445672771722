/* ***********  header css  ************  */
.header{
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
  }
.header_title{
   margin-left: 4.5rem;
}
.filledBtn{
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.3rem;
    border-radius: 5px;
    margin-left:2.3rem;
    margin-right: 1.5rem;
    font-size: 0.9rem;
}
.outlinedBtn{
    background-color: #fff;
    color: #2a9d8f;
    border: 1px solid #fff;
    padding: 0.6rem 1.8rem;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: 600;
    outline-style: auto;
    outline-color: #2a9d8f;
}
.heading{
    /* font-family: OpenSans-SemiBold; */
    font-size: 18px;
    font-weight: 600;
    color: black;
    padding: 10px 10px 0px 0px;
}
.sub_heading{
    padding: 10px 10px 0px 0px;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    color: rgb(107 107 107/ 47%);
    /* font-family: OpenSans-SemiBold; */
    margin-top: 0.2rem;
}
.leftContainer{
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem 0rem;
    /* width: 50%; */
}
.leftCalender{
    margin-top: 3rem;
}
.mrg_10{
    margin-right: 10px;
    padding: 0.6rem 3.5rem;
}
.leftInnerBox{
    width:230px;
    height:140px;
    border: 1px solid rgb(214, 214, 214);
    margin-right: 15px;
    background-color: #fff;
}
.rightContainer{
    /* width:60%; */
    margin-left: auto;
    
}

.innerItem1{
  font-size: 13px;
  padding: 25px 12px 5px 15px;
  color: black;
  font-weight: 600;
  font-family: Open Sans,sans-serif;
}
.innerItem2{
 color: rgb(118, 118, 118);
 font-size: 12px;
 padding-left: 15px;
 font-family: Open Sans,sans-serif;;
}
.innerItem3{
 padding: 10px 15px 15px;
 font-weight: 600;
 font-size: 30px;
}
.rightInnerBox{
    flex-direction: column;
    width:250px;
    height:140px;
    border: 1px solid rgb(214, 214, 214);
    background-color: #fff;
}
.firstLine{
    padding: 35px 10px 10px;
}
.thirdLine{
    margin-top: 10px;
}
.darkgreen{
    height: 15px;
    width: 15px;
    /* background-color: rgb(0,150,136); */
    background-color: rgb(0, 150, 136);
    border: 1px solid grey;
    margin-left: -38px;
}

.orange{
    height: 15px;
    width: 15px;
    margin-left: 10px;
    border: 1px solid grey;
    background-color: rgb(234, 180, 42);
    /* background-color: rgb(234,182,40); */
}
.aqua{
    height: 15px;
    width: 15px;
    /* background-color: rgb(211,240,237); */
    background-color: rgb(211, 240, 237);
    border: 1px solid grey;
    margin-left: 14px;
}
.campaign_title{
    font-size: 14px;
    color: rgb(12,12,12);
    margin-left: 10px;
    font-family: Open Sans,sans-serif;
}
.yellow{
    height: 15px;
    width: 15px;
    /* background-color: rgb(255,231,148); */
    background-color: rgb(255, 231, 148);
    border: 1px solid grey;
    margin-left: 10px;
}
.white1{
    height: 15px;
    width: 15px;
    border: 1px solid grey;
    background-color: rgb(255,255,255);
    margin-left: -17px;
}
.white2{
    height: 15px;
    width: 15px;
    border: 1px solid grey;
    background-color: rgb(255,255,255);
    margin-left: 10px;
}
.greyBottomBorder{
    width: 78%;
    margin-left: 146px;
    margin-top: 32px;
    border: 1px solid rgb(214,214,214);
    background-color: rgb(214,214,214);
}
.campaign_listing{
    width: 75%;
}
.zones{
    color: #262626;
    font-size: 14px;
    justify-content: space-between;
    font-weight: 700;
    margin-left: 15px;
    padding: 10px;
    text-transform: uppercase;
    
}
.listing_head{
    width: 100vw;
}
.listRow:nth-child(odd){
 background-color: #f1f1f1;
}
.itemsName{
    width:150px;
}
.empty_zone{
    width:10%;
}
.zones{
    width: 90%;
}
.tableContainer{
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 4px #eee;
    margin-bottom: 40px;
    /* width: 85vw; */
    /* margin: 10px 100px 30px 100px; */
}
.dashboardContainer{
    padding: 10px 50px ;
    background-color: #f1f1f1;
}
.colorBox1{
    display: inline-block;
    width: 22px;
    height: 20px;
    border: 1px solid lightgray;  
    border-right: none;
}
.colorBox2{
    display: inline-block;
    width: 22px;
    height: 20px;
    border: 1px solid lightgray;  
    border-left: none;
}
thead{
    color: #262626;
    font-size: 14px;
    font-weight: 700;
    margin-left: 15px;
    padding: 10px;
    text-transform: uppercase;
}
tbody{
    font-family: Open Sans,sans-serif;
    font-weight: 500;
    font-size: 17px;
}

/*******Date picker CSS*******/

.custom_date_picker{
    padding: 6px;
    /* width: 12%; */
    border: none;
    border-bottom: 1px solid #2a9d8f;
    background-color: transparent;
    margin-top: 4px;
    margin-left: 5px;
    font-size: 0.9rem;
}
.date_btn_active{
    background: #2a9d8f;
    border: 1px solid #2a9d8f;
    color: #fff;

}
.calendr_img{
    margin-top: 2px;
    margin-left: 11px;
}

.today_time_btn{
    padding: 10px;
    border: 1px solid rgb(39 39 39);
    color: rgb(13 13 13);
    background: #fff;
    border-radius:3px;
    margin-top: 40px;
    width: 80%;
    cursor: pointer;
}
.tomorrow_date_btn{
    padding: 10px;
    background: #fff;
    border: 1px solid rgb(39 39 39);
    color: rgb(13 13 13);
    border-radius:3px;
    margin-top: 40px;
    margin-left: 10px;
    width: 70%;
    cursor: pointer;
}
