.toolTip {
    position: relative;
}
.toolTip:hover .toolTipText {
    visibility: visible;
}
.toolTipText {
    visibility: hidden;
    width: 16rem;
    right: 0%;
    z-index: 1;
}
.tip {
    position: relative;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    margin-bottom: -2px;
    width: 0px;
    display: inline-block;
    margin-right: 4px;
}
.tipBlack {
    border-bottom: 8px solid #000000c2;
}
.tipWhite {
    border-bottom: 8px solid #bababa;
}

.toolText {
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 4px #e9f0ec;
}
.blackTheme {
    background-color: #000000c2;
    color: white;
}
.whiteTheme {
    background-color: white;
}