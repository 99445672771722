.navbar {
  display: inline-block;
  position: fixed;
  z-index: 15;
  /* background-color: #009688; */
}
/* .closeIcon { 
  width: 2.1rem;
  margin-bottom: 0.8rem;
  left: -100%;
  position: fixed;
  background-color: #009688;
  border-radius: 8px;
  padding: 10px 20px;
}
.closeIcon.active{
  left: 21vw;
  transition: 600ms;
} */
.iconDrawer {
  padding: 1.1rem 0.4rem 0.8rem 0.4rem;
  width: 1.8rem; 
}
.iconList {
  width: 1.5rem;
  margin-right: 0.6rem;
  margin-bottom: -0.3rem;
  margin-left: 0.3rem;
}
.navMenu {
  overflow: auto;
  padding: 0.6rem 0rem 0.6rem 0rem;
  background-color: #009688;
  position: fixed;
  z-index: 15;
  height: 100vh;
  width: 19vw;
  left: -100%;
  transition: 600ms;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navMenu.active {
  left: 0;
  transition: 600ms;
}
.nav-item {
  /* border: 0.2px solid white; */
  cursor: pointer;
  padding: 1rem 0.6rem 1rem 0rem;
  font-size: 0.9rem;
  /* border-bottom: 1px solid #ccc; */
  color: white;
  border-left: 2px solid #009688;
}
a {
  text-decoration: none;
  color: white;
}
.subList ul{
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 0.4rem;
  border-bottom: 1px solid #ccc;
  background-color: #09756a80;
}
.subList ul li {
  margin: 0.2rem 1.2rem;
  padding: 0.8rem 1.4rem;
  border-bottom: 1px solid #ccc;
  font-size: 0.95rem;
  color: white;
  cursor: pointer;
}
.subList ul li:hover {
  background: #43504f38;
  border-radius: 5px;
}
.subList ul li:last-child {
  border: none;
}
.selOption{
  border-left: 3.2px solid #f8f8f8;
  background: #00000038;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.dummyDiv {
  background-color: rgba(0, 0, 0, 0.36);
  color: white;
  position: fixed;
  z-index: 13;
  top: -100%;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 600ms ease-out;
  cursor: pointer;
}
.dummyDiv.active {
  opacity: 1;
  top: 0;
  transition: opacity 600ms ease-in;
}
.logoutBtn {
  margin: 2.5rem auto 3.8rem;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 8px;
  border: 2px solid #ffffff;
  background-color: #009688;
  color: white;
  font-weight: bold;
  width: 150px;
}
.iconList path, .iconList rect{
  fill: white;
}
.logoutIcon {
  margin-bottom: -0.4rem;
  height: 22px;
}
.logoutIcon path, .logoutIcon rect{
  fill:white;
}
.userDetail {
  color: whitesmoke;
  text-align: center;
  margin: 1rem 0;
}
.capital {
  text-transform: capitalize;
  margin-bottom: 0.4rem;
}
.flexSpaceBw{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* custom scroll bar for navbar only */

/* width */
.navContainer ::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.navContainer ::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.navContainer ::-webkit-scrollbar-thumb {
  background: #ccc; 
  border-radius: 8px;
}

/* Handle on hover */
.navContainer ::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc; 
}