/* ************** header css ************ */

.header{
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
  }
.header_title{
   margin-left: 4.5rem;
}

/* ***************  create campaign css  *************** */
label{
  font-weight: 400 !important;
}
input:disabled {
  cursor: not-allowed;
  background-color: rgb(243 243 243);
}

.width40{
  width: 40%;
}

.width50{
  width: 50%;
}

.title{
  padding: 0.6rem 0.6rem;
  border-radius: 5px;
}
.min_height_150{
  min-height: 150px; 
}
.main_heading {
  margin: 1.1rem 0;
  padding: 0.9rem;
  text-align: center;
  background-color: #e4fffc;
  border-radius: 10px;
  overflow-y: overlay;
}
.container{
  margin: 15px 0;
  border: 1px solid #ccc;
  box-shadow: 0px 3px 4px #eee;
  border-radius: 8px;
  padding: 1.4rem 1rem;
  background-color: #fff;
}

.isNewCheckbox {
  display: inline-block;
  background-color: #e4fffc;
  color: #2a9d8f;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.9rem 1.1rem;
  border: 1px solid #2a9d8f;
  accent-color: #2a9d8f;
  border-radius: 8px;
}

.isNewCheckbox > input {
  margin: 0;
  margin-right: 20px;
  display: inline-block;
  width: 18px;
  height: 18px;
}

.mrg_btm_3 {
  margin-bottom: 3rem;
}
.mrg_btm_2 {
  margin-bottom: 3rem;
}
.mrg_btm{
  margin-bottom: 0.8rem;
}

.mrg_tp{
  margin-top: 2rem;
}

.zone_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.list_item {
  width: 33%;
  margin-top: 30px;
  min-width: 210px;
}
.checkmark{
  font-weight: normal;
}

.checkbox {
  display: inline-block;
  width: 15px;
  height: 15px;
  appearance: none;
  margin-bottom: -2px;
  margin-right: 10px;
  accent-color: #2a9d8f;
  outline: 2px solid #2a9d8f;
  /* cursor: pointer; */
  border-radius: 1px;
}

.checkbox:checked{
 background-color: #2a9d8f;
 background-image: url('../../../../icons/check-mark.svg');
 background-size: contain;
 background-repeat: no-repeat;
}

.input_field {
  padding: 0.4rem 0.6rem;
  border: none;
  border-bottom: 2px solid #ccc;
  margin-right: 1.8rem;
  outline: none;
}

.input_field:disabled {
  background-color: #f8f8f8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 2px solid #e2e2e2;
}

.input_field:focus, .input_field:focus-visible {
  border-bottom: 2px solid #2a9d8b;
}

.deal_container {
  /* width: 50%; */
  padding: 0.8rem 0;
  color: #192A3E;
}

.sku_list_title {
  border-radius: 5px;
  /* background-color: #ececec; */
  padding: 0.4rem 0.6rem;
  margin-bottom: 0.6rem;
}

.sku_container {
  padding: 0.8rem 0;
  border-bottom: 2px dotted #ccc;
}

.sku_container:nth-child(odd) {
  background-color: #F5F6F8;
}

.sku_container:nth-child(even) {
  background-color: #fff;
}

.list_container{
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  /* margin-top: 0.4rem; */
  background: #f8f8f8;
  /* overflow: hidden; */
}

.list_thead{
  background-color: #EBF0F5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 500;
}

.grid_column_list{
  grid-template-columns: 0.8fr 1.7fr 1.5fr 1.3fr 1.5fr 2fr 1.4fr 0.8fr;
}

.sku_deatil_column{
  grid-template-columns: 1.2fr 1fr 1.2fr;
  margin-top: -20px;
}

.grid_column_segment {
  grid-template-columns: 1fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr;
}

.sku_img{
  border: 1px solid #ddd;
  border-radius: 5px;
}

.pd_sm{
  padding: 0.4rem 0.8rem;
}
.pd_sm_y{
  padding: 0.4rem 0;
}

.rank_field{
  width: 80px;
  padding: 5px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 0.6rem;
}

.rank_field:focus{
  border: 1px solid #2a9d8c;
}

.deal_field{
  width: 88px;
  padding: 0.4rem 0.6rem;
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 2px solid #ccc;
}

.deal_field:focus{
  border-bottom: 2px solid #2a9d8c;
}

.upload_btn{
  padding: 8px 12px;
  border: 1px solid #2a9d8c;
  background-color: white;
  border-radius: 5px;
  text-align: center;
}
.vertical_upload_btn{
  width: 72px;
  height: 93px;
}
.horizontal_upload_btn{
  width: 175px;
  height: 50px;
}
.outlined_btn{
  margin: 1.4rem 0;
  border: 1px solid #2a9c8d;
  background-color: white;
  color: #2a9c8d;
  padding: 0.6rem 1.8rem;
  border-radius: 5px;
  font-size: 0.9rem;
}

.big_btn{
  margin: 2.4rem 0.6rem;
  padding: 1rem 7.2rem;
  border-radius: 5px;
  font-size: 1rem;
}

.outlined_green_btn{
  border: 1px solid #2a9c8d;
  background-color: white;
  color: #2a9c8d;
}

.filled_green_btn{
  border: 1px solid #2a9c8d;
  background-color: #2a9c8d;
  color: white;
}

.white_outlined_container{
  background: #fff;
  border: 1px dashed #ccc;
  width: 75%;
  margin: 0.5rem auto;
  border-radius: 20px;
}
.deleteIcon{
  padding: 4px 4px;
  background-color: white;
  border: 1px solid red;
  border-radius: 4px;
}
.smallFilledBtn{
  background: #2a9d8f;
  color: white;
  border: 1px solid #2a9d8f;
  padding: 5px;
  border-radius: 6px;
  margin-left: 10px;
}
.goIcon{
  width: 15px;
  height: 18px;
  margin-bottom: -4px;
}
.rankChange{
  border-radius: 4px;
  background: #E3F0FF;
  color: #0762CE;
  padding: 6px;
}

.csvBtn{
  background-color: #fff;
  outline: none;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #2a9d8f;
  font-weight: 600;
  margin-right: 10px;
}
.icon22{
  width: 20px;
  height: 20px;
}
.greenIcon path{
  fill: #2a9d8f;
}