/* ***********  header css  ************  */

.header{
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
  }
.header_title{
   margin-left: 4.5rem;
}

.dropdown{
    min-width:200px;
}
.dropdownMenu{
    background-color: #fff;
    border: 1px solid #f8f8f8;
    outline-style: auto;
    outline-color: #2a9d8f;
    padding: 0.6rem;
    min-width: 200px;
    border-radius: 5px;
    position: relative;
    text-transform: capitalize;
    font-size: 0.9rem;
}
.dropdownList{
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid lightgray;
    border-radius: 9px;
    min-width: 200px;
    position: absolute;
}
.dropdownList li{
    background-color: white;
    border-bottom: 1px solid lightgray;
    padding: 9px 15px;
    text-transform: capitalize;
    font-size: 0.9rem;
}
.dropdownList li:first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
 }
 .dropdownList li:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
 }
 .dropdownList li:hover{
    background-color: #eef2f5;
    cursor: pointer;
 } 
 .mrgL{
    margin: 2.4rem 1.8rem;
 }
 .mrg_x_6{
    margin: 0rem 0.6rem;
}
.width33{
    width:33%;
}
.btnM{
    min-width: 9rem;
}
.filledBtn{
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
}
.filledSubmitBtn{
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    border-radius: 5px;
    font-size: 0.9rem;
}
.submit{
    margin-bottom: 4.5rem;
    margin-top:3.5rem;
    margin-right: 2.2rem;
    display: flex;
    justify-content: flex-end;  
}

/***** Coupon Listing******/
.container{
    padding: 1.6rem 1.8rem 2.4rem 1.8rem;
    background-color: #f1fffc;
    min-height: 100vh;
}
.thead{
    background-color: #2a9d8f;
    color: white;
    /* font-weight: bold; */
    /* padding: 16px; */
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-transform: uppercase;
}
.pd_bg{
    padding: 1rem 0.8rem
}
.width25{
   width: 25%;
   text-align:left;
   font-family: sans-serif;
}
.width_25{
    width: 25%;
}
.width20{
   width: 20%;
   text-align:left;
   font-family: sans-serif;
}
.width30{
  width: 30%;
  text-align:left;
  font-family: sans-serif;
}
.width35{
    width: 35%;
    text-align:left;
    font-family: sans-serif;
 }
 .tbody{
    border: 1px solid #e1e1e1;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #e5e5e5;
    /* font-size: 1.2rem; */
}
.trow{
    /* color: #555; */
    border-bottom: 1px solid #ececec;
}
.trow:last-child{
    border-bottom: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.trow:nth-child(odd){
    background-color: #f1fffc;

}
.trow:nth-child(even){
 background-color: white;
}
.DeleteIcon{
    margin-left: 1.2rem;
}

/*****************************/
.addCouponForm{
    background-color: #fff;
    width:66vw;
    border: 1px solid #ccc;
    margin: 50px auto;
}
.addCouponContainer{
    background-color: #f2f1ee;
    padding-top: 10px;
    padding-bottom: 10px;
}
.couponHeader{
    border-bottom: 1px solid #ccc;
}
.section_seperator{
    border-bottom: 3px dotted #ccc;
}
.filledBtn{
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    margin-right: 30px;
    border-radius: 5px;
    font-size: 0.9rem;
}
.heading{
    padding: 1.4rem;
}
.isActiveCheckbox {
    display: inline-block;
    background-color: #e4fffc;
    color: #2a9d8f;
    font-weight: 500;
    font-size: 1.1rem;
    padding: 0.6rem 1.2rem 0.6rem 0.7rem;
    margin: 1.2rem;
    border: 1px solid #2a9d8f;
    accent-color: #2a9d8f;
    border-radius: 5px;
}
  
.isActiveCheckbox > input {
    margin-right: 20px;
    display: inline-block;
    width: 18px;
    height: 18px;
}
.width_50{
    width: 50%;
    margin-right: 1.7rem;
}
.title{
    margin: 0.6rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #90aea2;
}
.inputField{
    padding: 0.6rem;
    border:none;
    border-bottom:1px solid #ccc;
    outline: none;
}
.inputField:focus , .inputField:focus-visible {
    border-bottom: 2px solid #2a9d8f;
}
.width100{
    width: 100%;
}
.width50{
    width: 50%;
}
.width90{
    width: 90%;
}
.width_30{
    width: 30%;
}
.secondRow{
    margin:1.9rem 0.8rem 0rem 1.1rem
}
.thirdRow{
    margin: 2.7rem 2.5rem 0rem 1.1rem;
}
.fourthRow{
    margin:2.7rem 2.5rem 0rem 1.1rem;
}
.fifthRow{
    margin: 2.7rem 2.5rem 2.1rem 1.1rem;
}
.mappedAgentDetails{
    padding: 2.1rem;
}
.newUserCheck{
    margin: 2.7rem 2.5rem 3rem 1.1rem;
}
.checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    accent-color: #2a9d8f;
    /* cursor: pointer; */
  }
.checkbox_title{
    display: inline-block;
    font-size: 0.8rem;
    text-transform: uppercase;
}
/*****************************************/

.custom_date_picker{
    padding: 6px;
    /* width: 12%; */
    border: none;
    outline: none;
    border-bottom: 2px solid #ccc;
    width:100%;
    margin-left: 5px;
    font-size: 0.9rem;
}
.order_banner_select_zone_heading {
  margin-top: 2rem;
  margin-left: 1.7rem;
  margin-bottom: 1.3rem;

}
.zone_listing{
    margin-bottom: 2.1rem;
}
.zone_heading{
    color: #90aea2;
}
.checkboxDiv{
    padding: 0.5rem;
    margin-left: 1.3rem;
}
.conditionalRow{
    margin:2.7rem 2.5rem 0rem 1.1rem;
}
.conditionalTitle{
    padding: 3.6rem 1.5rem 2rem 1.5rem;
    font-weight: 600;
    font-size: 1.3rem;
}

.searchInput {
    border: none;
    padding: 11px;
    font-size: 0.9rem;
    width: 70%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #2a9d8f;
    outline: none;
}

.searchIcon {
    background: #2a9d8f;
    padding: 7.5px;
    border: 1px solid #2a9d8f;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.searchList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid lightgray;
    border-radius: 9px;
    max-height: 200px;
    overflow-y: scroll;
    margin-left: 0.8rem;
    width: 76%;
}

.searchList li{
    background-color: white;
    border-bottom: 1px solid lightgray;
    color: #2a9d8f;
    padding: 9px 15px;
    font-weight: bold;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 0.9rem;
}
.searchList li:hover{
    background-color: #eef2f5;
    cursor: pointer;
 } 

 .searchList li:first-child{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
 }
 .searchList li:last-child{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
 }

 .outerList{
    width: 75%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-left: 1.6rem;
    margin-top:2.6rem;
 }
 .outerItemList{
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-bottom: 1px solid lightgray;
    color: #2a9d8f;
    padding: 9px 15px;
 }
 .outerList:first-child{
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
 }
 .outerList:last-child{
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
 }