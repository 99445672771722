.time_schedule_text {
    background: #ebf0f5;
    width: 100%;
    padding: 10px;
    height: 33px;
    border-radius: 5px;
}

.width45 {
    width: 45%;
}

.title {
    font-weight: 400;
    font-size: 1rem;
    margin-left: 5px;
    /* margin-top: 7%; */
}

.date_container{
    padding: 1.8rem 0.4rem;
}

.date_outlined_btn{
    border: 1px solid #ccc;
    background: white;
    color: #666;
    padding: 0.4rem 0.9rem;
    border-radius: 8px;
    margin-top: 0.8rem;
    margin-right: 0.8rem;
}

.date_input_box {
    color: rgb(25 42 62);
    border: none;
    border-bottom: 2px solid #ddd;
    margin-top: 12px;
    padding: 10px;
    outline: none;
}

.date_input_box:focus, .date_input_box:focus-visible {
    border-bottom: 2px solid #2a9d8c;
}

.slot_btn_active {
    background-color: #2a9d8c;
    color: #fff;
    border: 1px solid #2a9d8c;
}

.error_txt {
    color: #d50000;
    font-size: 0.9rem;
    font-style: italic;
    margin-top: 5px;
    font-family: OpenSans-Italic;
}