/************* header css*******/
.header{
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
  }
.header_title{
   margin-left: 4.5rem;
}
.filledBtn{
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.3rem;
    border-radius: 5px;
    margin-left:2.3rem;
    margin-right: 1.5rem;
    font-size: 0.9rem;
}
.outlinedBtn{
    background-color: #fff;
    color: #2a9d8f;
    border: 1px solid #fff;
    padding: 0.6rem 1.8rem;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: 600;
    outline-style: auto;
    outline-color: #2a9d8f;
}
.thead{
    background-color: #2a9d8f;
    color: white;
    /* font-weight: bold; */
    /* padding: 16px; */
    font-size: 0.9rem;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-transform: uppercase;
}
.container{
    padding: 1.6rem 1.8rem 2.4rem 1.8rem;
    background-color: #f1fffc;
    min-height: 90vh;
}
.pd_bg{
    padding: 1rem 0.8rem
}
.width10{
    width: 10%;
    text-align:center;
    font-family: sans-serif;
}
.width20{
    width: 20%;
    text-align:center;
    font-family: sans-serif;
}
.trow{
    /* color: #555; */
    border-bottom: 1px solid #ececec;
}
.trow:last-child{
    border-bottom: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.trow:nth-child(odd){
    background-color: #f1fffc;

}
.trow:nth-child(even){
 background-color: white;
}
.tbody{
    border: 1px solid #e1e1e1;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #e5e5e5;
    /* font-size: 1.2rem; */
}
.inputField{
    padding: 0.6rem;
    border-radius: 6px;
    border:1px solid #fff;
    outline-style: auto;
    outline-color: #2a9d8f;
    width:80px;
    /* width: 100%; */
}
.disabledField{
    padding: 0.6rem;
    border-radius: 6px;
    border:1px solid #ccc;
    outline-style: auto;
    outline-color: #ccc;
    color: #666;
    width:80px;
}
.checkbox {
    margin: 9px;
    width: 16px;
    height: 16px;
    accent-color: #2a9d8f;
}
.tooltip{
    width:20px;
    height: 20px;
    margin-left: 7px;
    margin-bottom: -3px;
    margin-top:2px
}
.addCouponForm{
    background-color: #fff;
    width:66vw;
    border: 1px solid #ccc;
    margin: 50px auto;
}
.addCouponContainer{
    background-color: #f2f1ee;
    padding-top: 10px;
    padding-bottom: 10px;
}
.couponHeader{
    border-bottom: 1px solid #ccc;
}
.heading{
    padding: 1.4rem;
}
.inputSegmentField{
    padding: 0.6rem;
    border:none;
    border-bottom:1px solid #ccc;
    outline: none;
}
.inputSegmentField:focus , .inputSegmentField:focus-visible {
    border-bottom: 2px solid #2a9d8f;
}
.width_50{
    width: 50%;
    margin-right: 1.7rem;
}
.title{
    margin: 0.6rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #90aea2;
}
.width75{
    width: 75%;
}
.firstRow{
    margin:1.9rem 0.8rem 0rem 1.1rem
}
.secondRow{
    margin: 4.7rem 0.8rem 0rem 1.1rem;
}
.seperatorRow{
    margin:1.9rem 0.8rem 0rem 1.1rem
}
.submit{
    margin-bottom: 4.5rem;
    margin-top:3.5rem;
    margin-right: 2.2rem;
    display: flex;
    justify-content: flex-end;  
}
.filledSubmitBtn{
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;    
    border-radius: 5px;
    font-size: 0.9rem;
}
.updateBtn{
    position: fixed;
    bottom: 0;
    padding: 15px;
    right: 0;
}
.uploadImg {
    margin: 1.8rem 0.6rem;
  }
.title_banner {
    margin: 0.6rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #2a9d8f;
}
.imgContainer {
    width: 400px;
    height: 150px;
    margin-top: 0.2rem;
}
.uploadBtn {
    background-color: #fff;
    padding: 0.6rem 0.8rem;
    font-weight: bold;
    color: #2a9d8f;
    outline: none;
    margin-top: 20px;
    border: 1px solid #f2f2f2;
    outline: 2px solid #2a9d8f;
    border-radius: 4px;
    width: 100px;
  }
  .width100{
    width: 100%;
  }
  .width87{
    width: 87%;
  }