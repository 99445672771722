.header {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
}

.header_title {
    margin-left: 1.5rem;
}
ul, li{
    padding: 0;
}
.btn_outlined{
    border: 1px solid #2a9d8b;
    border-radius: 5px;
    padding: 0.4rem 0.8rem;
    background-color: #fff;
    color: #2a9d8b;
}

/* Dropdown CSS*/

.dropdown {
    width: 200px;
    display: inline-block;
}

.dropdownMenu {
    background-color: #fff;
    border: 1px solid #f8f8f8;
    outline-style: auto;
    outline-color: #2a9d8f;
    padding: 0.6rem;
    width: 200px;
    border-radius: 5px;
    position: relative;
    text-transform: capitalize;
    font-size: 0.9rem;
}

.dropdownList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 9rem;
    border: 1px solid lightgray;
    border-radius: 9px;
    width: 200px;
    position: absolute;
}

.dropdown_list {
    overflow-y: scroll;
}

.dropdownList li {
    background-color: white;
    border-bottom: 1px solid lightgray;
    padding: 9px 15px;
    text-transform: capitalize;
    font-size: 0.9rem;
}

.dropdownList li:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.dropdownList li:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
}

.dropdownList li:hover {
    background-color: #eef2f5;
    cursor: pointer;
}

.mrg_x_6 {
    margin: 0rem 0.6rem;
}
.mrg_btm_3{
    margin-bottom: 3rem;
}
.mrg_btm{
    margin-bottom: 0.8rem;
}
.mrg_lft{
    margin-left: 2rem;
}
.pd_b_15{
    padding-bottom: 15px;
}
.brdGrey{
    border: 1px solid #ccc;
}
.brdGreen{
    border: 1px solid #2a9d8f;
}
/*Buttons CSS*/

.btnM {
    min-width: 9rem;
}

.filledBtn {
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
}

.outlinedBtn {
    background-color: #fff;
    color: #2a9d8f;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 0.9rem;

}
/* Banner Listing CSS*/
.pd_bg {
    padding: 0.8rem 0.6rem;
}


.pd_bg1 {
    padding: 1rem 0.4rem;
}

.container {
    padding: 1.6rem 1.8rem 2.4rem 1.8rem;
    background-color: #F1FFFC;
    min-height: 100vh;
}
.listingGrid{
    /* grid-template-columns: 1fr .5fr 1fr 1fr 1fr 0.5fr 0.7fr ; */
    grid-template-columns: 1fr 0.4fr 0.9fr 1fr 1fr 0.7fr 0.7fr ;
}

.thead {
    background-color: #2a9d8f;
    color: white;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.tbody {
    border: 1px solid #e1e1e1;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 0px 5px #e5e5e5;
}

.trow {
    border-bottom: 1px solid #ececec;
}

.trow:last-child {
    border-bottom: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.trow:nth-child(odd) {
    background-color: #F1FFFC;
}

.trow:nth-child(even) {
    background-color: white;
}

.width_50 {
    width: 50%;
}

.width_50>input,
.width_50 button {
    width: calc(100% - 40px);
}

.title {
    margin: 0.6rem;
    text-transform: uppercase;
    font-size: 0.8vw;
}

/*Order Banner*/
.createWidget {
    margin: 1.1rem 0;
    padding: 0.9rem;
    text-align: center;
    background-color: #e4fffc;
    border-radius: 10px;
}

.addContainer{
    margin: 15px 0;
    border: 1px solid #ccc;
    box-shadow: 0px 3px 4px #eee;
    border-radius: 8px;
    padding: 1rem 1.4rem;
}
.mrg_top_2 {
    margin-top: 2rem;
}
.mrg_top_3 {
    margin-top: 3rem;
}
.editIcon{
    padding: 0 1em 0 0;
}

.list_item {
    width: 25%;
    margin-top: 30px;
    min-width: 210px;
    text-transform: capitalize;
}
.customRadio{
    display: inline-block;
    width: 18px;
    height: 18px;
    -webkit-appearance: none;
    appearance: none;
    margin-bottom: 3px;
    margin-right: 10px;
    accent-color: #2a9d8f;
    outline: 2px solid #2a9d8f;
    border-radius: 50%;
    border: 2px solid #fff;
}
.customRadio:checked {
    background-color: #2a9d8f;
}

.checkbox {
    display: inline-block;
    width: 18px;
    height: 18px;
    -webkit-appearance: none;
    appearance: none;
    margin-bottom: 8px;
    margin-right: 10px;
    accent-color: #2a9d8f;
    outline: 2px solid #2a9d8f;
}

.checkbox:checked {
    background-color: #2a9d8f;
    background-image: url('../../../../icons/check-mark.svg');
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
}
.checkbox:disabled{
    background-color: #a0afad;
    border: #a0afad;
    accent-color: #a0afad;
    outline: 2px solid #a0afad;
}

.delete_count{
    padding: 5px 10px;
    margin-right: 1em;
    border-radius: 5px;
    border: 1px solid #fff;
}
.checkbox_white {
    display: inline-block;
    width: 18px;
    height: 18px;
    -webkit-appearance: none;
    appearance: none;
    margin-right: 10px;
    accent-color: #2a9d8f;
    outline: 2px solid #ffffff;
    /* cursor: pointer; */
}

.checkbox_white:checked {
    background-color: #2a9d8f;
    background-image: url('../../../../icons/check-mark.svg');
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
}


.textarea_field {
    padding: 0px;
    border: none;
    outline: none;
    border-bottom: 2px solid #a1a1a1;
    margin: 0.4rem 0;
    font-size: 0.8vw;

    height: 55px;
    resize: none;
}

.input_field {
  padding: 0.4rem 0.6rem;
  border: none;
  border-bottom: 2px solid #a1a1a1;
  margin-bottom: 0.8rem;
  outline: none;
  font-size: 0.8vw;
}

input.input_field:disabled {
  background-color: rgba(0,0, 0,0);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 2px solid #e2e2e2;
  cursor: not-allowed;
  color: #ccc;
}

.input_field:focus, .input_field:focus-visible {
  border-bottom: 2px solid #2a9d8b;
}  

.searchInput {
    border: none;
    padding: 6px;
    font-size: 0.9rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #2a9d8f;
    outline: none;
}

.searchIcon {
    background: #2a9d8f;
    padding: 3px;
    border: 1px solid #2a9d8f;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.isActiveCheckbox {
    display: inline-block;
    background-color: #e4fffc;
    color: #2a9d8f;
    font-weight: 500;
    font-size: 16px;
    padding: 0.9rem 1.4rem 0.9rem 0.9rem;
    border: 1px solid #2a9d8f;
    accent-color: #2a9d8f;
    border-radius: 8px;
}

.isActiveCheckbox>input {
    margin-right: 20px;
    display: inline-block;
    width: 18px;
    height: 18px;
}

.land_page {
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    color: #2a9d8f;
    margin-bottom: 9px;
}

.separator{
    border-bottom: 1px dashed #ccc;
    padding-bottom: 1.6rem;
}

.imageSeparator{
    border-bottom: 1px dashed rgb(155, 152, 152);
    padding-bottom: 0.6rem;
    margin-bottom: 0.6rem;
}
.imgContainer {
    width: 100%;
    height: 220px;
    margin-top: 0.2rem;
    background-color: #F3F9FF;
    margin-bottom: 8px;
}

.greyBrd{
    border: 1px solid #ccc; 
    border-radius: 5px;
    padding: 5px;
}

.imageDeleteIcon{
    bottom: 22px;
    right: 13px;
    background-color: #e8e8e8d9;
}

.cancelIcon{
    width: 18px;
    margin-bottom: -5px;
}

.imgPlaceholder {
    padding: 12px 6px;
    border: 1px solid #2a9d8f;
    background-color: #FFF;
    border-radius: 5px;
}
.imgPlaceholderDisabled {
    padding: 12px 6px;
    border: 2px solid #ccc;
    background-color: #FFF;
    border-radius: 5px;
}

.uploadBtn {
    background-color: #fff;
    padding: 0.6rem 1.6rem;
    font-weight: bold;
    color: #2a9d8f;
    outline: none;
    border: 1px solid #2a9d8f;
    border-radius: 4px;
    margin-top: 6px;
}

.ht_wd_70 {
    height: 70px;
    max-width: 140px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.product_detail_wrapper{
    display: grid;
    grid-template-columns: 1.3fr 0.9fr 1fr 1fr 1fr;
}
.grid_template_two{
    grid-template-columns: 1fr 1fr;
}
.quality_attribute{
    grid-template-columns: 1fr;
    padding: 0 1em;
    border-radius: 10px;
    margin-right: 0.8rem;
}
.quality_attribute input{
    background-color: transparent;
}

.temp_col {
    grid-template-columns: 1fr 3.5fr;
}

.grid_template{
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.grid_template_sku{
    grid-template-columns: 1fr 4fr 1fr;
}
.file_grid{
    grid-template-columns: 4fr 1fr;
}
.grid_template_images {
    grid-template-columns: 1fr 1fr 1fr 1.5fr;
}
.pdf_image{
    width: 68px;    
    border-radius: 6px;
}
.pdf_image_container{
    border: 2px dashed #ccc;
    border-radius: 6px;
    padding: 0 1rem;
    width: 300px;
}
.preview_container{
    width: 290px;
    height: 530px;
    overflow-y: scroll;
}

/* sku listing css */
.sku_thead{
    border-radius: 5px;
    color: white;
    margin: 1rem 0;
    background-color: #009688;
}
.sku_row{
    background-color: #f0f9ea;
    /* width: 95%; */
    border-radius: 0.8rem;
    border: 1px solid #d7d7d7;
    position: relative;
    font-size: 0.9vw;
    margin-right: 0.8rem;
}
.sku_img{
    width: 50px;
    border: 1px solid #cecece;
    border-radius: 8px;
}
.labImage{
    height: 60px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
}

.height_width_30px{
    height: 30px;
    width: 30px;
}