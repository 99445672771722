/* ***********  header css  ************  */

.header{
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
  }
.header_title{
   margin-left: 4.5rem;
}

.mrg_x_6{
    margin: 0rem 0.6rem;
}

.dropdown{
    min-width:200px;
}
.dropdownMenu{
    background-color: #fff;
    border: 1px solid #f8f8f8;
    outline-style: auto;
    outline-color: #2a9d8f;
    padding: 0.6rem;
    min-width: 200px;
    border-radius: 5px;
    position: relative;
    text-transform: capitalize;
    font-size: 0.9rem;
}
.dropdownList{
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid lightgray;
    border-radius: 9px;
    min-width: 200px;
    position: absolute;
}
.dropdownList li{
    background-color: white;
    border-bottom: 1px solid lightgray;
    padding: 9px 15px;
    text-transform: capitalize;
    font-size: 0.9rem;
}
.dropdownList li:first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
 }
 .dropdownList li:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
 }
 .dropdownList li:hover{
    background-color: #eef2f5;
    cursor: pointer;
 } 

 .pd_bg{
    padding: 1rem 0.8rem
}
.container{
    padding: 1.6rem 1.8rem 2.4rem 1.8rem;
    background-color: #f1fffc;
    min-height: 100vh;
}
.thead{
    background-color: #2a9d8f;
    color: white;
    /* font-weight: bold; */
    /* padding: 16px; */
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-transform: uppercase;
}
.tbody{
    border: 1px solid #e1e1e1;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #e5e5e5;
    /* font-size: 1.2rem; */
}
.trow{
    /* color: #555; */
    border-bottom: 1px solid #ececec;
}
.trow:last-child{
    border-bottom: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.trow:nth-child(odd){
    background-color: #f1fffc;

}
.trow:nth-child(even){
 background-color: white;
}
.width25{
    width: 25%;
    text-align:left;
    font-family: sans-serif;
}
.green{
    color: #2a9d8f;
    font-weight: 600;
}
.btnM{
    min-width: 9rem;
}
.filledBtn{
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
}