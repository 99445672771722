.header {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.6rem 1.6rem;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 4px #ccc;
    min-height: 2.4rem;
}

.header_title {
    margin-left: 4.5rem;
    margin-top: 0.5rem;
}

ul,
li {
    padding: 0;
}

/* Dropdown CSS*/

.dropdown {
    width: 200px;
    display: inline-block;
}

.dropdownMenu {
    background-color: #fff;
    border: 1px solid #f8f8f8;
    outline-style: auto;
    outline-color: #2a9d8f;
    padding: 0.6rem;
    width: 200px;
    border-radius: 5px;
    position: relative;
    text-transform: capitalize;
    font-size: 0.9rem;
}

.dropdownList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 18rem;
    border: 1px solid lightgray;
    border-radius: 9px;
    width: 200px;
    position: absolute;
}

.dropdown_list {
    overflow-y: scroll;
}

.dropdownList li {
    background-color: white;
    border-bottom: 1px solid lightgray;
    padding: 9px 15px;
    text-transform: capitalize;
    font-size: 0.9rem;
}

.dropdownList li:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.dropdownList li:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
}

.dropdownList li:hover {
    background-color: #eef2f5;
    cursor: pointer;
}

.mrg_x_6 {
    margin: 0rem 0.6rem;
}

.mrg_btm_3 {
    margin-bottom: 3rem;
}

/*Buttons CSS*/

.btnM {
    min-width: 9rem;
}

.filledBtn {
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 1.8rem;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
}

.outlinedBtn {
    background-color: #fff;
    color: #2a9d8f;
    border: 1px solid #2a9d8f;
    font-weight: bolder;
    padding: 0.6rem 2.2rem;
    margin-left: 0.8rem;
    border-radius: 5px;
    font-size: 0.9rem;

}

/* Banner Listing CSS*/
.pd_bg {
    padding: 1rem 0.8rem;
}

.pd_bg1 {
    padding: 1rem 0.4rem;
}

.container {
    padding: 1.6rem 1.8rem 2.4rem 1.8rem;
    background-color: #F1FFFC;
    min-height: 100vh;
}

.thead {
    background-color: #2a9d8f;
    color: white;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-transform: uppercase;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 0.5fr;
}

.tbody {
    border: 1px solid #e1e1e1;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #e5e5e5;
}

.trow {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 0.5fr;
    border-bottom: 1px solid #ececec;

}

.trow:last-child {
    border-bottom: none;
    /* border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; */
}

.trow:nth-child(odd) {
    background-color: #F1FFFC;
}

.trow:nth-child(even) {
    background-color: white;
}

.width_50 {
    width: 50%;
}

.width_50>input,
.width_50 button {
    width: calc(100% - 40px);
}

.title {
    margin: 0.6rem;
    text-transform: uppercase;
    font-size: 0.8rem;
}

/*Order Banner*/
.createWidget {
    margin: 1.1rem 0;
    padding: 0.9rem;
    text-align: center;
    background-color: #e4fffc;
    border-radius: 10px;
}

.addContainer {
    margin: 15px 0;
    border: 1px solid #ccc;
    box-shadow: 0px 3px 4px #eee;
    border-radius: 8px;
    padding: 1.4rem 2rem;
}

.mrg_top_3 {
    margin-top: 3rem;
}

.list_item {
    width: 25%;
    margin-top: 30px;
    min-width: 210px;
    text-transform: capitalize;
}

.checkbox {
    display: inline-block;
    width: 18px;
    height: 18px;
    -webkit-appearance: none;
    appearance: none;
    margin-bottom: -4px;
    margin-right: 10px;
    accent-color: #2a9d8f;
    outline: 2px solid #2a9d8f;
    cursor: pointer;
}

.checkbox:checked {
    background-color: #2a9d8f;
    background-image: url('../../../../icons/check-mark.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.input_field {
    padding: 0.4rem 0.6rem;
    border: none;
    border-bottom: 2px solid #ccc;
    margin-right: 1.8rem;
    outline: none;
}

input.input_field:disabled {
    background-color: rgba(0, 0, 0, 0);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 2px solid #e2e2e2;
    cursor: not-allowed;
    color: #ccc;
}

.input_field:focus,
.input_field:focus-visible {
    border-bottom: 2px solid #2a9d8b;
}

.searchInput {
    border: none;
    padding: 6px;
    font-size: 0.9rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #2a9d8f;
    outline: none;
}

.searchIcon {
    background: #2a9d8f;
    padding: 3px;
    border: 1px solid #2a9d8f;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

/* .seperator{
    border-bottom: 1px solid #ccc;
} */
.isActiveCheckbox {
    display: inline-block;
    background-color: #e4fffc;
    color: #2a9d8f;
    font-weight: 500;
    /* margin-top: 0.8rem; */
    max-width: 90px;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    font-size: 16px;
    padding: 0.4rem 1.1rem 0.4rem 0.6rem;
    border: 1px solid #2a9d8f;
    accent-color: #2a9d8f;
    border-radius: 8px;
}

.isActiveCheckbox>input {
    margin-right: 20px;
    display: inline-block;
    width: 18px;
    height: 18px;
}

.land_page {
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    color: #2a9d8f;
    margin-bottom: 9px;
}

.separator {
    border: 1px solid #eaeaea;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;
}

.imgContainer {
    width: 400px;
    height: 150px;
    margin-top: 0.2rem;
}

.uploadBtn {
    background-color: #fff;
    padding: 0.6rem 1.6rem;
    font-weight: bold;
    color: #2a9d8f;
    outline: none;
    border: 1px solid #2a9d8f;
    border-radius: 4px;
    margin-top: 6px;
}

.ht_wd_70 {
    height: 70px;
    /* width: 185px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.temp_col {
    grid-template-columns: 1fr 3.5fr;
}

.grid_template {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid_template_sku {
    grid-template-columns: 1fr 4fr 1fr 1fr;
}

/* sku listing css */
.sku_thead {
    border-radius: 5px;
    color: white;
    margin: 1rem 0;
    background-color: #009688;
}

.sku_row {
    background-color: #f0f9ea;
    width: 100%;
    margin-bottom: 0.56em;
    border-radius: 0.8rem;
    border: 1px solid #d7d7d7;
    position: relative;
}

.sku_img {
    width: 60px;
    border: 1px solid #cecece;
    margin-right: 10px;
}

.campaign_listing {
    grid-template-columns: 1.8fr 0.8fr 0.8fr 2fr 0.9fr 0.3fr;
}

.campaign_listing_header {
    grid-template-columns: 1.8fr 0.8fr 0.8fr 2fr 0.9fr 0.3fr;
    padding: 1rem 2rem;
}

.strip_title {
    font-size: 1em;
    color: #2a9d8b
}

.strip_title_container {
    font-size: 1em;
    padding-bottom: 1vh;
    padding-top: 1vh;
}

.strip_description,
.strip_end_description,
.strip_start_description {
    color: #5d5d5d;
    font-size: .75em;
    margin-top: 1vh;
}

/*************** sub header css **************/
.heading {
    font-size: 18px;
    font-weight: 600;
    color: black;
    padding: 10px 10px 0px 0px;
}

.sub_heading {
    padding: 10px 10px 0px 0px;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    color: rgb(107 107 107/ 47%);
    margin-top: 0.2rem;
}

.leftContainer {
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem 4rem;
    width: 50%;
}

.rightContainer {
    /* flex-direction: column; */
    width: 60%;
}

.custom_date_picker {
    padding: 6px;
    /* width: 12%; */
    border: none;
    border-bottom: 1px solid #2a9d8b;
    background-color: transparent;
    margin-top: 4px;
    margin-left: 5px;
    font-size: 0.9rem;
}

.date_btn_active {
    background: #2a9d8f;
    border: 1px solid #2a9d8f;
    color: #fff;
}

.calendr_img {
    margin-top: 2px;
    margin-left: 11px;
}

.today_time_btn {
    padding: 10px;
    border: 1px solid rgb(39 39 39);
    color: rgb(13 13 13);
    background: #fff;
    border-radius: 3px;
    margin-top: 40px;
    width: 80%;
    cursor: pointer;
}

.tomorrow_date_btn {
    padding: 10px;
    background: #fff;
    border: 1px solid rgb(39 39 39);
    color: rgb(13 13 13);
    border-radius: 3px;
    margin-top: 40px;
    margin-left: 10px;
    width: 70%;
    cursor: pointer;
}

.calendar {
    display: flex;
    margin-top: 2rem;
}

.rightInnerBox {
    width: 200px;
    min-height: 110px;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 5px;
    background-color: #fff;
    margin-left: auto;
    margin-right: 1.5rem;
    color: #7a7a7a; 
}
.legend{
    margin: 1rem;
}
.legendBox {
    height: 15px;
    width: 15px;
    margin-right: 15px;
}
.darkgreen {
    background-color: rgb(0, 150, 136);
    border: 1px solid grey;
}
.yellow {
    background-color: rgb(255, 231, 148);
    border: 1px solid grey;
}
.tabContainer {
    padding-left: 3rem;
    margin-bottom: -4px;
}
.tabButtons {
    padding: 0.5rem 1.4rem;
    margin: 0 3px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 400;
    background-color: #fff;
    color: #bcbcbc;
}

.tabButtons.active {
    color: #2a9d8f;
    border-bottom: 1px solid transparent;
}

.listingContainer {
    margin-bottom: 30px;
}

.tabHeader {
    border: 1px solid #eaeaea;
    margin: 0 3rem 1.5rem 3rem;
    background-color: #fff;
    color: #7a7a7a;
    border-radius: 2px;
    font-weight: 600;
}

.zoneInput {
    accent-color: #2a9d8f;
    width: 15px;
    height: 15px;
    margin-right: 20px;
}

.campaign_listing {
    padding: 1rem 2rem;
    background-color: #FFFEF0;
    border-radius: 1em;
    margin: 0 3rem;
}

.campaign_list_item {
    grid-template-columns: 1fr 1fr 0.4fr;
}

.campaign_listing_container {
    padding-bottom: 2rem;
}
.bgLightYellow{
    background-color: rgb(255, 231, 148);
}

.width30 {
    width: 30%;
}

.sectionTitle {
    padding: 0.8rem;
    border: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;
    text-align: left;
}

.width10 {
    width: 10%;
}

.width20 {
    width: 20%;
}

.width60 {
    width: 60%;
}

.deal_container {
    margin-left: 1vw;
    margin-top: 2vh;
    margin-bottom: 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.detail_container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: auto;
    width: 90vw;
    border-radius: 18px;
    flex-wrap: wrap;
    /* background-color: rgb(255 251 219); */
    background-color: #FFFEF0;;
}

.detail_container_two {
    margin-left: 1vw;
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
}

.pack_container {
    display: flex;
    flex-direction: row;
    font-size: 1em;
}

.pack_title {
    font-weight: 600;
    margin-right: 0.25vw;
}

.pack_description {
    color: #5d5d5d;
}

.title_one {
    margin-top: 1vh;
    font-weight: 600;
    font-size: 1.25em;
}

.title_two {
    font-weight: 600;
    font-size: 1.25em;
}

.cities_container {
    color: #5d5d5d;
    display: flex;
    flex-direction: row;
    padding-right: 1vw;
    margin: 1vh;
    justify-content: space-evenly;
}

.table_container {
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    /* width: 75%; */
    flex-wrap: wrap;
    justify-content: space-between;
}

/* .table_entry {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.table_title {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    font-weight: 600;
}

.ind_entry {
    margin-top: 1vh;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
}

.ind_entry .city {
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ind_entry .burn {
    text-align: center;
}

.ind_entry img {
    width: 21px;
    height: 21px;
}

.remove_button {
    color: rgb(0 157 139);
    border-radius: 0.5em;
    border: 1px solid rgb(0 157 139);
    background-color: white;
    margin-left: 1vw;
    margin-top: 4vh;
    /* width: 5vw; */
    font-weight: 600;
    font-size: 1rem;
    padding-left: 1vw;
    padding-right: 1vw;
    /* padding: 0.75vh; */
    line-height: 2rem;
    cursor: pointer;
}

.disable_btn {
    color: gray;
    border: 1px solid #918b8bd9;
    cursor: not-allowed;
    pointer-events: none;
}