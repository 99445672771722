.calenderWrapper{
    padding-right: 1rem;
    margin-right: 0;
    border-radius: 5px;
    border: 1px solid #2a9d8f;
    background: white;
    position: relative;
}
.calenderInput {
    padding: 0.5rem 0rem 0.5rem 0.6rem;
    border-radius: 5px;
    outline: none;
    border: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    /*position: absolute;
    top: 11.8px;
    right: 615px;
    padding: 0.6rem 0.4rem 0.6rem 6rem;
    */
    opacity: 1;
    display: block;
    background-repeat:  no-repeat;
    width: 15px;
    height: 15px;
    border-width: thin;
    position: absolute;
    right: .5rem;

}